import { ScrollablePane, ScrollbarVisibility } from "@fluentui/react";
import React from "react";
import TreeMenu from "react-simple-tree-menu";
import {
  BusinessObjectType,
  IDestinationLocationInfo,
  ISection,
} from "./SearchList/SearchList";
import SectionTreeItem from "./SectionTreeItem";

interface ISectionTreeProps {
  data: ISection[];
  chosenModule: BusinessObjectType;
  updateTree: () => void;
  onSelectDestination: (destination?: IDestinationLocationInfo) => void;
  selectedDestination?: IDestinationLocationInfo;
}

// eslint-disable-next-line import/prefer-default-export
export const SectionTree = ({
  data,
  chosenModule,
  updateTree,
  onSelectDestination,
  selectedDestination,
}: ISectionTreeProps) => {
  return (
    <ScrollablePane
      scrollbarVisibility={ScrollbarVisibility.auto}
      styles={{
        root: {
          position: "relative",
          height: "calc(35vh - 23px)",
        },
        contentContainer: {
          overflowX: "hidden",
          position: "relative",
          height: "100%",
        },
      }}
      className="section-tree-sidebar"
    >
      <TreeMenu
        data={data}
        onClickItem={(item: any) => {
          if (selectedDestination) {
            onSelectDestination({
              containerId: selectedDestination.containerId,
              containerTypeName: selectedDestination.containerTypeName,
              containerName: selectedDestination.containerName,
              partnerTypeName: selectedDestination.partnerTypeName,
              sectionId: item.sectionType ? item.sectionId : undefined,
              sectionTypeName: item.sectionType,
              virtualFolderId: selectedDestination.virtualFolderId,
            });
          }
        }}
        cacheSearch={false}
      >
        {({ items }) => (
          <div className="tree-list">
            <ul
              className="tree-list__items"
              style={{
                padding: "10px 10px 0",
                outline: "none",
              }}
            >
              {items.map(({ key, ...props }) => (
                <SectionTreeItem
                  key={key}
                  activeKey={
                    selectedDestination &&
                    (props.sectionId === selectedDestination.sectionId ||
                      (!props.sectionType && !selectedDestination.sectionId))
                  }
                  module={chosenModule}
                  updateData={updateTree}
                  {...props}
                />
              ))}
            </ul>
          </div>
        )}
      </TreeMenu>
    </ScrollablePane>
  );
};
