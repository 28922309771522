import {
  Action,
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { fork } from "redux-saga/effects";
import { authReducer } from "./auth";
import { bingMapsReducer } from "./bingMaps";
import campaignSlice from "./campaign/campaignSlice";
import { clientsReducer, clientsSaga } from "./clients/clients";
import { locationsReducer, locationsSaga } from "./locations/locations";
import { settingsReducer, settingsSaga } from "./settings";
import { taxonomySaga } from "./taxonomy/sagas";
import { taxonomyReducer } from "./taxonomy/taxonomy";
import { userRoleReducer } from "./userRole";
import { usersReducer, usersSaga } from "./users";
import {
  valueMonitoringReducer,
  valueMonitoringSaga,
} from "./valueMonitoring/valueMonitoringPage";

export const history = createBrowserHistory();

const reducer = combineReducers({
  taxonomy: taxonomyReducer,
  userRole: userRoleReducer,
  bingMaps: bingMapsReducer,
  settings: settingsReducer,
  clients: clientsReducer,
  auth: authReducer,
  users: usersReducer,
  valueMonitoring: valueMonitoringReducer,
  locations: locationsReducer,
  router: connectRouter(history),
  campaign: campaignSlice.reducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer,
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
    routerMiddleware(history),
    sagaMiddleware,
  ],
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = any;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppThunkPromise<T> = ThunkAction<
  Promise<T>,
  RootState,
  null,
  Action<string>
>;

export type LoadStatus = "none" | "loading" | "success" | "error";

export function* rootSaga() {
  yield fork(taxonomySaga);
  yield fork(settingsSaga);
  yield fork(usersSaga);
  yield fork(valueMonitoringSaga);
  yield fork(locationsSaga);
  yield fork(clientsSaga);
}

sagaMiddleware.run(rootSaga);

export const selectRouterLocation = (s: RootState) => s.router.location;
