import React from "react";

import { ActionButton } from "office-ui-fabric-react";

import { useTranslation } from "react-i18next";

type Props = {
  resetStates: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  locationFieldsDisabled: boolean;
  formObject: any;
};

function ResetForm({
  resetStates,
  setFieldValue,
  locationFieldsDisabled,
  formObject,
}: Props) {
  const { t } = useTranslation();

  const config = fieldsConfig(locationFieldsDisabled, formObject);

  return (
    <ActionButton
      iconProps={{ iconName: "Clear" }}
      onClick={() => {
        resetStates();
        config.forEach(({ name, value }) => {
          setFieldValue(name, value);
        });
      }}
    >
      {t("rvm.clearForm.label")}
    </ActionButton>
  );
}

export default ResetForm;

const fieldsConfig = (locationFieldsDisabled, formObject) => [
  {
    name: "buildingValues",
    value: "",
  },
  {
    name: "equipmentValues",
    value: "",
  },
  {
    name: "stockValues",
    value: "",
  },

  {
    name: "totalPd",
    value: "",
  },
  {
    name: "businessInteruptionValues12Months",
    value: "",
  },
  {
    name: "totalPdBi",
    value: "",
  },
  {
    name: "indemnityPeriodCode",
    value: null,
  },
  {
    name: "businessInteruptionValuesBasedOnIndemnityPeriod",
    value: "",
  },
  {
    name: "totalPdBiBasedOnIndemnityPeriod",
    value: "",
  },
  {
    name: "clientComment",
    value: "",
  },
  ...(!locationFieldsDisabled
    ? [
        {
          name: "desiredLocationOwningStatusCode",
          value: null,
        },
        {
          name: "desiredLocationOwningStatusDate",
          value: "",
        },
        {
          name: "desiredLocationStatusCode",
          value: null,
        },
        {
          name: "desiredLocationStatusDate",
          value: "",
        },
        {
          name: "currencyCode",
          value: null,
        },
      ]
    : []),
  {
    name: "statusDate",
    value: "",
  },
  ...(!formObject.coveragePeriodFromDate
    ? [
        {
          name: "coveragePeriodFromDate",
          value: undefined,
        },
      ]
    : []),
  ...(!formObject.coveragePeriodToDate
    ? [
        {
          name: "coveragePeriodToDate",
          value: undefined,
        },
      ]
    : []),
  {
    name: "buildingValuesComment",
    value: "",
  },
  {
    name: "equipmentValuesComment",
    value: "",
  },

  {
    name: "businessInteruptionValues12MonthsComment",
    value: "",
  },
  {
    name: "desiredLocationStatusCodeComment",
    value: "",
  },
];
