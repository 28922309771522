import { useSelector } from "../store/hooks";

function useIsInternalUser(): boolean {
  const userRole = useSelector((s) => s.userRole.userRole);

  const isInternalUser = userRole === "internal";

  return isInternalUser;
}

export default useIsInternalUser;
