import moment from "moment";
import { numberFieldValueFormat } from "../../types/types";

const defaultLSTaxonomyKey = "tmsTaxonomyCodes";

export const getUrlContextFromUrl = (): any => {
  const url = new URL(window.location.href);

  const policyId = url.searchParams.get("polizzeoid");
  const organizationId = url.searchParams.get("organisationoid");
  const partnerId = url.searchParams.get("partneroid");

  let type: any;

  if (organizationId !== null) {
    type = "legal-client";
  } else if (partnerId !== null) {
    type = "individual-client";
  } else if (policyId !== null) {
    type = "policy";
  } else {
    type = "main-menu";
  }

  return {
    type,
    params: { policyId, organizationId, partnerId },
  };
};

export const convertStringToNumber = (
  s: string,
  format: numberFieldValueFormat
): number => {
  if (format === "amount" || format === "amountAllowNegative") {
    return parseFloat(s.replace(".", "").replace(",", "."));
  } else {
    return Number(s);
  }
};

export const getTaxonomyCodesFromLocalStorage = (
  lsKey: string = defaultLSTaxonomyKey,
  taxonomyName?: string
) => {
  const lsTaxCodes = localStorage.getItem(lsKey);

  if (!lsTaxCodes) {
    return undefined;
  }

  const taxCodes = JSON.parse(lsTaxCodes);

  if (taxonomyName) {
    // returns array for given taxonomy code name or undefined if given name doesn't exist
    return taxCodes[taxonomyName];
  }

  // returns whole taxonomy object with all taxonomy code arrays
  return taxCodes;
};

export const getTaxonomyItemId = (
  taxonomyName: string,
  taxonomyItemCode: string,
  lsKey: string = defaultLSTaxonomyKey
) => {
  const taxCodes = getTaxonomyCodesFromLocalStorage(lsKey, taxonomyName);
  console.log(taxCodes, "TAX CODES");

  if (!taxCodes) {
    return undefined;
  }

  const findItem = taxCodes.filter((taxItem: any) => {
    return taxItem.code === taxonomyItemCode;
  });

  return findItem.length ? findItem[0].id : undefined;
};

export const formatDate = (date: Date | undefined): string =>
  moment(date).format("DD.MM.YYYY")!;

export const maxNumberOfSearchResults = 100000;

export const pageSize = 30;
export const defaultSort = { field: "sortableEventDateTime", dir: "desc" };
export const panelTypes = {
  COLUMN_OPTIONS: "COLUMN_OPTIONS",
};
