import { CommandBarButton } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { routes } from "../../config/routes";
import { useSelector } from "../../store/hooks";

type Props = {
  text?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  path?: string;
};

const backBtnStyle = {
  root: {
    height: "100%",
  },
};

export const CancelButton = ({ text, onClick, isDisabled, path }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const clientId = useSelector((s) => s.valueMonitoring.formObject?.clientId);
  const locationId = useSelector(
    (s) => s.valueMonitoring.formObject?.locationId
  );
  const propertyInformationId = useSelector(
    (s) => s.valueMonitoring.formObject?.propertyInformationId
  );
  const historyData = useSelector((s) => s.valueMonitoring.historyData);
  const goTo = () => {
    history.push(
      routes.valueMonitoringForLocation.getPath(
        clientId,
        locationId,
        historyData.length > 0
          ? historyData[0].propertyInformationId
          : propertyInformationId
      )
    );
  };
  const buttonText = t(text || "rvm.cancelEdit.label");
  return (
    <CommandBarButton
      iconProps={{ iconName: "Back" }}
      onClick={goTo}
      styles={backBtnStyle}
      text={buttonText}
      disabled={isDisabled}
    />
  );
};
