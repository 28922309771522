import {
  getTheme,
  IconButton,
  IStyle,
  mergeStyles,
  mergeStyleSets,
} from "office-ui-fabric-react";
import React, { useState } from "react";

interface IStyles {
  list?: IStyle;
  columnItemWrapper?: IStyle;
  columnItemValue?: IStyle;
}

interface Props {
  items?: {
    value?: string;
    onClick?: () => void;
    icon?: JSX.Element;
    onRender?: (expanded?: boolean) => JSX.Element;
  }[];
  text?: string;
  showTranslateIcon?: boolean;
  lineNumbers?: number;
  styles?: IStyles;
}

const ExtendableColumnItem = ({
  items,
  text,
  showTranslateIcon,
  lineNumbers,
  styles,
}: Props) => {
  const theme = getTheme();

  const [showMore, setShowMore] = useState<boolean>(false);
  const [displayTextShowMoreIcon, setDisplayTextShowMoreIcon] =
    useState<boolean>(false);

  const generalStyles = mergeStyleSets({
    columnItemWrapper: {
      position: "relative",
      display: "flex",
      alignItems: "center",
    },
    columnItemWrapperText: {
      position: "relative",
      display: "flex",
      alignItems: "baseline",
    },
    list: {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },
    listItem: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      whiteSpace: "pre-wrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    columnItemValue: {
      whiteSpace: "pre-wrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    link: {
      selectors: {
        "&:hover": {
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
    },
  });

  if (text) {
    return (
      <div className={generalStyles.columnItemWrapperText}>
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
            display: showMore ? "initial" : "-webkit-box",
            WebkitLineClamp: lineNumbers || 1,
            WebkitBoxOrient: "vertical",
          }}
          ref={(ref: any) => {
            ref &&
              !showMore &&
              setDisplayTextShowMoreIcon(ref.offsetHeight < ref.scrollHeight);
          }}
        >
          {text}
        </span>
        {displayTextShowMoreIcon && (
          <IconButton
            styles={{
              root: { height: 15, color: theme.palette.neutralSecondaryAlt },
              icon: { fontSize: "12px" },
            }}
            iconProps={{
              iconName: showMore ? "CaretUpSolid8" : "CaretDownSolid8",
            }}
            onClick={() => setShowMore(!showMore)}
          />
        )}
      </div>
    );
  }

  if (items && !items.length) return null;

  if (!showMore) {
    return (
      <div
        className={`${generalStyles.columnItemWrapper}${
          styles?.columnItemWrapper
            ? " " + mergeStyles(styles?.columnItemWrapper)
            : ""
        }`}
      >
        {items && items[0].icon ? items[0].icon : null}
        <span
          className={`${generalStyles.columnItemValue}${
            styles?.columnItemValue
              ? " " + mergeStyles(styles?.columnItemValue)
              : ""
          } ${items && items[0].onClick ? generalStyles.link : ``}`}
          onClick={items && items[0].onClick ? items[0].onClick : undefined}
        >
          {items &&
            ((items[0].onRender && items[0].onRender(false)) || items[0].value)}
        </span>
        {items && items.length > 1 && (
          <IconButton
            styles={{
              root: {
                position: "absolute",
                right: 0,
                height: 15,
                color: theme.palette.black,
              },
              icon: { fontSize: "12px" },
            }}
            iconProps={{ iconName: "CaretDownSolid8" }}
            onClick={() => setShowMore(!showMore)}
          />
        )}
      </div>
    );
  }

  return (
    <ul
      className={`${generalStyles.list}${
        styles?.list ? " " + mergeStyles(styles?.list) : ""
      }`}
    >
      {items?.map((item: any, index: number) => {
        return (
          <li key={index} className={generalStyles.listItem}>
            {item && item.icon ? item.icon : null}
            <span
              className={item.onClick ? generalStyles.link : undefined}
              onClick={item.onClick}
            >
              {item.onRender ? item.onRender(true) : item.value}
            </span>
            {index === 0 && (
              <IconButton
                iconProps={{ iconName: "CaretUpSolid8" }}
                onClick={() => setShowMore(!showMore)}
                styles={{
                  root: {
                    position: "absolute",
                    right: 0,
                    height: 15,
                    color: theme.palette.black,
                  },
                  icon: { fontSize: "12px" },
                }}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default ExtendableColumnItem;
