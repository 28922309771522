export interface IHeaderConfig {
  [key: string]: string | boolean;
}

export const headerConfig: IHeaderConfig = {
  application_name: "GOS | Risks Value Monitoring",
  search_visible: false,
  tooltipsStatus: false,
  notifications_visible: false,
  help_visible: true,
  darkMode: localStorage.getItem("darkMode") === "true" ? true : false,
};

// // DEFAULT VALUES
// application_name: "GrECo Header",
// app_launcher_visible: true,
// notifications_visible: true,
// help_visible: true,
// account: true,
// search_visible: true,
// logo_visible: true,
// title_visible: true,
// tooltipsStatus: true,
// settings_visible: true,
// settings_tooltip_switch: true,
// settings_language_settings: true,
// settings_time_zone_settings: true,

// defaultLanguage: "en-GB",
// defaultTimeZone: "UTC",

// disableActionSpinner: false,
// test: false,
// debug: false,

// userMenuShowMyProfileLink: true,
// userMenuShowMyAccountLink: true,
