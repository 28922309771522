import { PrimaryButton } from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addDocument } from "../../../api/documentatioApi";
import { toast } from "../../../components/FluentToast";
import { useSelector } from "../../../store/hooks";
import { setDocumentConfiguration } from "../../../store/locations/locations";
import { IDocumentConfigurationSettings } from "../../../types/types";
import { formBase64String } from "../../../utils/utils";

type Props = {
  isInternalUserDocument: boolean;
  handleSetConfigurationSettings: (
    data: IDocumentConfigurationSettings
  ) => void;
  handleSetFile: (state: null) => void;
  file: File;
};

const formatFile = async (file) => {
  if (!file) return;
  const data = await {
    name: file.name,
    base64String: await formBase64String(file),
  };
  return data;
};

function AddNewDocument({
  isInternalUserDocument,
  file,
  handleSetFile,
  handleSetConfigurationSettings,
}: Props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [dataLoading, setDataLoading] = useState(false);

  const clientId = useSelector((s) => s.clients.clientId);

  const handleAddNewDocument = async () => {
    try {
      setDataLoading(true);
      const payloadFile = await formatFile(file);
      const response = await addDocument({
        clientId,
        isInternalUserDocument: isInternalUserDocument,
        data: payloadFile,
      });

      const data = response
        ? {
            ...response,
            settingValue: JSON.parse(response.settingValue),
          }
        : null;

      handleSetConfigurationSettings(data);
      handleSetFile(null);
      dispatch(
        setDocumentConfiguration(data.settingValue.InternalUserDocuments)
      );
    } catch (error: any) {
      toast.error(error?.message);
      setDataLoading(false);
    } finally {
      setDataLoading(false);
    }
  };

  return (
    <PrimaryButton disabled={dataLoading} onClick={handleAddNewDocument}>
      {t("rvm.addDocument.label")}
    </PrimaryButton>
  );
}

export default AddNewDocument;
