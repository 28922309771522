import { mergeStyleSets } from "@fluentui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  deleteConfigurationSettings,
  updateConfigurationSettings,
} from "../../../api/settingConfigurationApi";
import { toast } from "../../../components/FluentToast";
import { Flex } from "../../../components/styled";
import { useSelector } from "../../../store/hooks";
import { setDocumentConfiguration } from "../../../store/locations/locations";
import {
  EConfigurationSetting,
  IDocumentConfigurationSettings,
} from "../../../types/types";
import ClearDocConfig from "./ClearDocConfig";
import ExternalDocuments from "./ExternalDocuments";
import InternalDocuments from "./InternalDocuments";

type Props = {
  dataLoading: boolean;
  configurationSettings: IDocumentConfigurationSettings;
  handleSetConfigurationSettings: (
    data: IDocumentConfigurationSettings
  ) => void;
  handleSetDataLoading: (state: boolean) => void;
};

function DocumentList({
  dataLoading,
  configurationSettings,
  handleSetConfigurationSettings,
  handleSetDataLoading,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const clientId = useSelector((s) => s.clients.clientId);

  const internalDocuments =
    configurationSettings?.settingValue?.InternalUserDocuments;

  const externalDocuments =
    configurationSettings?.settingValue?.ExternalUserDocuments;

  const handleUpdateConfigurationSettings = useCallback(async () => {
    try {
      handleSetDataLoading(true);
      const response = await updateConfigurationSettings({
        clientId,
        data: {
          clientId: Number(clientId),
          locationId: null,
          settingName: EConfigurationSetting.Document,

          rowVersion: configurationSettings.rowVersion,
          settingValue: JSON.stringify(configurationSettings.settingValue),
        },

        configurationSettingId: configurationSettings?.configurationSettingId,
      });
      const data = response
        ? {
            ...response,
            settingValue: JSON.parse(response.settingValue),
          }
        : null;
      handleSetConfigurationSettings(data);
      handleSetDataLoading(false);

      dispatch(
        setDocumentConfiguration(data.settingValue.InternalUserDocuments)
      );

      toast.success(t("rvm.success"));
    } catch (error: any) {
      toast.error(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, configurationSettings]);

  const handleDeleteDocument = useCallback(
    async (url, isInternal) => {
      let confData;
      if (isInternal) {
        const InternalUserDocuments = internalDocuments.filter(
          (item) => item.Url !== url
        );
        confData = {
          ...configurationSettings,
          settingValue: {
            InternalUserDocuments,
            ExternalUserDocuments: externalDocuments,
          },
        };
      } else {
        const ExternalUserDocuments =
          externalDocuments.filter((item) => item.Url !== url) || [];
        confData = {
          ...configurationSettings,
          settingValue: {
            InternalUserDocuments: internalDocuments,
            ExternalUserDocuments,
          },
        };
      }

      const response = await updateConfigurationSettings({
        clientId,
        data: {
          clientId: Number(clientId),
          locationId: null,
          settingName: EConfigurationSetting.Document,

          rowVersion: confData.rowVersion,
          settingValue: JSON.stringify(confData.settingValue),
        },

        configurationSettingId: confData?.configurationSettingId,
      });
      const data = response
        ? {
            ...response,
            settingValue: JSON.parse(response.settingValue),
          }
        : null;
      handleSetConfigurationSettings(data);
      handleSetDataLoading(false);
      dispatch(
        setDocumentConfiguration(data.settingValue.InternalUserDocuments)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [internalDocuments, externalDocuments]
  );

  const handleDeleteConfiguration = async () => {
    try {
      handleSetDataLoading(true);

      await deleteConfigurationSettings({
        clientId,
        configurationSettingId: configurationSettings.configurationSettingId,
        rowVersion: configurationSettings.rowVersion,
      });

      handleSetConfigurationSettings({
        ...configurationSettings,
        settingValue: {
          InternalUserDocuments: [],
          ExternalUserDocuments: [],
        },
      });
      dispatch(setDocumentConfiguration([]));
      handleSetDataLoading(false);

      toast.success(t("rvm.success"));
    } catch (error: any) {
      handleSetDataLoading(false);

      toast.success(error?.message);
    }
  };

  return (
    <div className={sectionClasses.container}>
      <InternalDocuments
        handleUpdateConfigurationSettings={handleUpdateConfigurationSettings}
        externalDocuments={externalDocuments}
        handleDeleteDocument={handleDeleteDocument}
        handleSetConfigurationSettings={handleSetConfigurationSettings}
        internalDocuments={internalDocuments}
      />

      <ExternalDocuments
        handleUpdateConfigurationSettings={handleUpdateConfigurationSettings}
        externalDocuments={externalDocuments}
        handleDeleteDocument={handleDeleteDocument}
        handleSetConfigurationSettings={handleSetConfigurationSettings}
        internalDocuments={internalDocuments}
      />

      <Flex justifyContent="flex-end">
        {/* <PrimaryButton
            disabled={dataLoading}
            onClick={handleUpdateConfigurationSettings}
          >
            {t("rvm.saveConfig.label")}
          </PrimaryButton> */}
        {/* <DefaultButton
            disabled={dataLoading}
            onClick={handleDeleteConfiguration}
          >
            {t("rvm.resetConfig.label")}
          </DefaultButton> */}
        <ClearDocConfig
          handleReset={handleDeleteConfiguration}
          disabled={dataLoading}
        />
      </Flex>
    </div>
  );
}

export default DocumentList;

export const sectionClasses = mergeStyleSets({
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: 50,
  },
  sectionWrap: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: 10,
  },
  sectionTitle: {
    padding: 0,
    margin: 0,
    fontWeight: 600,
    fontSize: 18,
  },
});
