import { useFormikContext } from "formik";
import { useEffect } from "react";
import { stringToNumber } from "../../../utils/utils";

const indemnityPeriodMapList = [12, 12, 18, 24];

export const useBusinessInteruptionValuesBasedOnPi = () => {
  const formik = useFormikContext();
  const businessInteruptionValues12Months =
    formik.values["businessInteruptionValues12Months"] &&
    formik.values["businessInteruptionValues12Months"] !== ""
      ? stringToNumber(formik.values["businessInteruptionValues12Months"])
      : 0;
  const indemnityPeriodCode =
    formik.values["indemnityPeriodCode"] &&
    formik.values["indemnityPeriodCode"] !== null
      ? formik.values["indemnityPeriodCode"].value
      : 0;
  const indemnityPeriod = indemnityPeriodMapList[indemnityPeriodCode];

  useEffect(() => {
    const businessInteruptionValuesBasedOnIndemnityPeriod =
      (businessInteruptionValues12Months / 12.0) * indemnityPeriod;
    const total =
      businessInteruptionValuesBasedOnIndemnityPeriod.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    if (formik.dirty && !formik.isSubmitting) {
      formik.setFieldValue(
        "businessInteruptionValuesBasedOnIndemnityPeriod",
        total
      );
    }
  }, [
    businessInteruptionValues12Months,
    formik.touched["businessInteruptionValues12Months"],
    formik.values["businessInteruptionValues12Months"],
    indemnityPeriodCode,
    formik.touched["indemnityPeriodCode"],
    formik.values["indemnityPeriodCode"],
  ]);
};
