import axios from "axios";

const DMS_API = process.env.REACT_APP_BASE_URL_DMS;
const CRM_API = process.env.REACT_APP_BASE_URL_CRMV2;
const POL_API = process.env.REACT_APP_BASE_URL_POLV2;

export const getFolders = async (container) => {
  const partnerTypeNameParams = container.ptype
    ? `&PartnerTypeName=${container.ptype}`
    : "";
  const sectionIdParams = container.sid ? `&SectionId=${container.sid}` : "";
  const sectionTypeNameParams = container.stype
    ? `&SectionTypeName=${container.stype}`
    : "";
  const sectionIBOParams = container.ibo ? `&IBOType=${container.ibo}` : "";

  const response = await axios.get(
    `${DMS_API}GetFoldersForContainer/?ContainerId=${container.cid}&ContainerTypeName=${container.ctype}${partnerTypeNameParams}${sectionIdParams}${sectionTypeNameParams}${sectionIBOParams}`
  );

  const sortedResponse = response?.data?.sort((a, b) =>
    a.folderName.toLowerCase().localeCompare(b.folderName.toLowerCase())
  );
  return sortedResponse;
};

export const getProjectsListPost = async (
  skip: number,
  sort?: { field: string; dir: "asc" | "desc" } | string,
  searchPattern?: string,
  pageSize?: number,
  filter?: string
): Promise<{ projects: any[]; total: number }> => {
  const sortObj = () => {
    if (!sort) return;
    if (typeof sort === "object") return `${sort.field} ${sort.dir}`;
    else if (typeof sort === "string" && sort !== "unsorted") return `${sort}`;
  };

  const data = {
    count: true,
    top: pageSize || 20,
    skip: skip,
    orderby: [sortObj()],
    search: searchPattern,
    filter,
  };

  let url = CRM_API as string;
  url += `/search-projects`;

  const res = await axios.post(url, data);

  const json = res.data;
  const total = res.status === 204 ? 0 : json.count;
  const listResults: any[] =
    res.status === 204 ? [] : json.results.map((item: any) => item.document);

  return { projects: listResults, total };
};

export const getPoliciesList = async (
  skip: number,
  sort: { field: string; dir: "asc" | "desc" },
  searchPattern: string,
  filter?: string,
  pageSize?: number
): Promise<{ policies: any[]; total: number }> => {
  let url = POL_API as string;
  url += "/search";
  url += "?$form=json";
  url += "&$count=true";
  url += `&$top=${pageSize || "20"}`;
  url += `&$skip=${skip}`;
  url += `&$filter=${filter}`;
  url += `&$orderby=${sort.field} ${sort.dir}`;

  if (searchPattern) {
    url += `&search=${searchPattern}`;
  }

  const res = await axios.get(url);

  const json = res.data;
  const total = res.status === 204 ? 0 : json["@odata.count"];
  const listResults: any[] =
    res.status === 204 ? [] : json.value.map((item: any) => item.document);

  return { policies: listResults, total };
};

export const getExternalPartner = async (
  partnerId: string,
  partnerTypeId: number,
  optionalQuery = {}
) => {
  //   const query = queryString.stringify(optionalQuery);
  const query = new URLSearchParams(optionalQuery);
  const queryString = query.toString();
  try {
    return await axios({
      method: "get",
      url: `${CRM_API}/external-get-partner?partnerID=${partnerId}&partnerTypeID=${partnerTypeId}&${queryString}`,
    });
  } catch (err) {
    return err;
  }
};

export const getProjectSpecialists = async (
  name: string,
  skip: number = 0,
  top: number = 10
) => {
  try {
    return await axios({
      method: "GET",
      url: `${CRM_API}/search-project-specialists?name=${name}&skip=${skip}&top=${top}`,
    });
  } catch (err) {
    return err;
  }
};

export const clientSearch = async (body: any) => {
  try {
    return await axios({
      method: "POST",
      url: `${CRM_API}/search`,
      data: body,
    });
  } catch (err) {
    return err;
  }
};
