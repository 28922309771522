import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
  color?: string;
}

export const LocationsIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '22'}
      height={props.height ? props.height : '28'}
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="10" cy="9.5" rx="5" ry="5.5" fill="#BAE8EE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9999 9.55C20.9999 11.1604 20.5547 12.6668 19.7807 13.9528L12.4499 26.65L5.11949 13.9534C4.34524 12.6672 3.8999 11.1606 3.8999 9.55C3.8999 4.82797 7.72787 1 12.4499 1C17.1719 1 20.9999 4.82797 20.9999 9.55ZM12.4499 14.3C15.0733 14.3 17.1999 12.1734 17.1999 9.55002C17.1999 6.92666 15.0733 4.80002 12.4499 4.80002C9.82655 4.80002 7.69991 6.92666 7.69991 9.55002C7.69991 12.1734 9.82655 14.3 12.4499 14.3Z"
        fill="url(#paint0_linear_422_1053)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1 9.55C18.1 11.1605 17.6547 12.6669 16.8806 13.953L9.55 26.65L2.21945 13.9531C1.44528 12.667 1 11.1605 1 9.55C1 4.82797 4.82797 1 9.55 1C14.272 1 18.1 4.82797 18.1 9.55ZM9.55 14.3C12.1734 14.3 14.3 12.1734 14.3 9.55002C14.3 6.92666 12.1734 4.80002 9.55 4.80002C6.92665 4.80002 4.8 6.92666 4.8 9.55002C4.8 12.1734 6.92665 14.3 9.55 14.3Z"
        fill="url(#paint1_linear_422_1053)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_422_1053"
          x1="5.3249"
          y1="5.275"
          x2="16.2499"
          y2="16.675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006283" />
          <stop offset="0.458333" stopColor="#00688B" />
          <stop offset="0.973958" stopColor="#028AB7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_422_1053"
          x1="5.31818"
          y1="2.35714"
          x2="8.4026"
          y2="16.2987"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.182292" stopColor="#00ACCD" />
          <stop offset="0.677083" stopColor="#00D8F3" />
          <stop offset="0.973958" stopColor="#00D0EA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
