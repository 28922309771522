import { css } from "@emotion/react";
import { TooltipHost } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavSearch } from "../../components/navigation/NavSearch";
import { useSelector } from "../../store/hooks";
import { setSearch } from "../../store/locations/locations";
import { AppDispatch } from "../../store/store";

export const Search = ({ selection }) => {
  const search = useSelector((s) => s.locations.searchFilter);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <TooltipHost content={t("rvm.searchTooltip")}>
      <NavSearch
        value={search}
        placeholder={t("rvm.search")}
        onChange={(value) => {
          dispatch(setSearch(value));
          selection.setAllSelected(false);
        }}
        css={css`
          margin-right: 10px;
          .ms-SearchBox {
            border-color: #9eb4cc;
          }
        `}
      />
    </TooltipHost>
  );
};
