import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useUnmount } from "react-use";
import { CrmSideBarContext } from "../../components/Sidebar/CrmSideBarContext";
import { setClientId } from "../../store/clients/clients";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";
import {
  getLastPropertyInfoRoutine,
  getPropertyInfoRoutine,
  hideAddLocationDialog,
  hideDeletePropertyInfoDialog,
  loadPropertyInfoForLocationRoutine,
  loadPropertyInfoRoutine,
  resetState,
  setHistoryData,
  setLocation,
  setShowInvitationForm,
  setShowReminderForm,
} from "../../store/valueMonitoring/valueMonitoringPage";
import { setYupLocale } from "../../utils/setYupLocale";
import AddLocationDialog from "./dialogs/AddLocationDialog";
import ApproveFormDialog from "./dialogs/ApproveForm";
import DeletePropertyInfoDialog from "./dialogs/DeletePropertyInfoDialog";
import { Dialog } from "./dialogs/Dialog";
import RejectFormDialog from "./dialogs/RejectForm";
import SendInvitationFormDialog from "./dialogs/SendInvitationFormDialog";
import SendReminderFormDialog from "./dialogs/SendReminderFormDialog";
import SubmitFormDialog from "./dialogs/SubmitFormDialog";

import useIsInternalUser from "../../hooks/useIsInternalUser";
import ValueMonitoringForm from "./components/ValueMonitoringForm/ValueMonitoringForm";

const isGOS = process.env.REACT_APP_GOS === "true";

const ValueMonitoring = () => {
  const { clientId, locationId, propertyInformationId } = useParams() as any;
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const formRef = useRef(null);

  const formObject = useSelector((s) => s.valueMonitoring.formObject);
  const data = useSelector((s) => s.valueMonitoring.data);

  const showInvitationForm = useSelector(
    (s) => s.valueMonitoring.showInvitationForm
  );

  const showReminderForm = useSelector(
    (s) => s.valueMonitoring.showReminderForm
  );

  const selectedLocation = useSelector(
    (s) => s.valueMonitoring.selectedLocation
  );

  const showDeletePropertyInfoDialog = useSelector(
    (s) => s.valueMonitoring.deleteDialog.isOpen
  );
  const showAddLocationDialog = useSelector(
    (s) => s.valueMonitoring.addLocationDialog.isOpen
  );

  const [showApproveForm, setShowApproveForm] = useState(false);
  const [showRejectForm, setShowRejectForm] = useState(false);
  const [showSubmitForm, setShowSubmitForm] = useState(false);
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);

  const isInternalUser = useIsInternalUser();
  const handleSetFile = useCallback((file: any) => {
    setFile(file);
  }, []);

  const handleSetFilename = useCallback((filename: any) => {
    setFilename(filename);
  }, []);

  const crmSideBarContext = useContext(CrmSideBarContext);

  const handleShowApproveForm = useCallback((value: boolean) => {
    setShowApproveForm(value);
  }, []);

  const handleShowRejectForm = useCallback((value: boolean) => {
    setShowRejectForm(value);
  }, []);
  const handleShowSubmitForm = useCallback((value: boolean) => {
    setShowSubmitForm(value);
  }, []);

  useEffect(() => {
    if (clientId) dispatch(setClientId(clientId));
  }, [clientId]);

  useEffect(() => {
    if (isInternalUser) {
      const id = !locationId ? 0 : Number(locationId);
      const type = !locationId ? "" : 900000000009905;
      //const type = !clientId ? "" : client ? client.clientType : 900000000003140; //partnerData.businessObjectType;
      const url = "Rvm";
      if (crmSideBarContext) {
        crmSideBarContext.setupSidebar(id, type, url);
      }
    }
  }, [crmSideBarContext, clientId, locationId, isInternalUser]);

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  useUnmount(() => {
    dispatch(resetState());
  });

  useEffect(() => {
    if (isGOS) {
      if (!propertyInformationId) {
        dispatch(
          getLastPropertyInfoRoutine.trigger({
            clientId,
            locationId,
          })
        );
      } else {
        dispatch(
          getPropertyInfoRoutine.trigger({
            clientId,
            locationId,
            propertyInformationId,
          })
        );
      }
    } else {
      dispatch(loadPropertyInfoRoutine.trigger({ clientId }));
    }
  }, [clientId, locationId, propertyInformationId]);

  useEffect(() => {
    if (clientId && locationId && data?.length > 0) {
      const location = data.find(
        (dataEl) =>
          dataEl.location.clientId + "" === clientId &&
          dataEl.location.locationId + "" === locationId
      )?.location;
      if (location) {
        dispatch(
          setLocation({
            item: {
              value: location.locationId,
              label: location.locationName,
            },
          })
        );
      }
    }
  }, [data, clientId, locationId]);

  useEffect(() => {
    if (selectedLocation) {
      dispatch(
        loadPropertyInfoForLocationRoutine.trigger({
          clientId: selectedLocation.clientId,
          locationId: selectedLocation.locationId,
          propertyInformationId: propertyInformationId
            ? propertyInformationId
            : selectedLocation.propertyInformationId,
        })
      );
    } else {
      dispatch(setHistoryData([]));
    }
    setFile(null);
  }, [selectedLocation, propertyInformationId]);

  useEffect(() => {
    if (formObject && formObject.documentPath) {
      setFilename(formObject.documentPath);
    } else {
      setFilename(null);
    }
  }, [file, formObject]);

  useEffect(() => {
    setYupLocale(t);
  }, [t]);

  return (
    <>
      <ValueMonitoringForm
        formRef={formRef}
        handleSetFile={handleSetFile}
        handleSetFilename={handleSetFilename}
        file={file}
        filename={filename}
        handleShowApproveForm={handleShowApproveForm}
        handleShowRejectForm={handleShowRejectForm}
        handleShowSubmitForm={handleShowSubmitForm}
      />
      {showApproveForm ? (
        <ApproveFormDialog
          close={() => {
            setShowApproveForm(false);
          }}
        />
      ) : null}
      {showRejectForm ? (
        <RejectFormDialog
          close={() => {
            setShowRejectForm(false);
          }}
        />
      ) : null}
      {showSubmitForm ? (
        <SubmitFormDialog
          values={formRef?.current.values}
          filename={filename}
          file={file}
          close={() => {
            setShowSubmitForm(false);
          }}
        />
      ) : null}
      {showDeletePropertyInfoDialog ? (
        <DeletePropertyInfoDialog
          close={() => {
            dispatch(hideDeletePropertyInfoDialog({}));
          }}
        />
      ) : null}
      {showInvitationForm ? (
        <SendInvitationFormDialog
          values={[selectedLocation]}
          close={() => {
            dispatch(setShowInvitationForm(false));
          }}
          coveragePeriodFromDate={
            formObject.coveragePeriodFromDate
              ? moment(formObject.coveragePeriodFromDate)
                  .add(1, "year")
                  .toDate()
              : moment().endOf("year").toDate()
          }
          coveragePeriodToDate={
            formObject.coveragePeriodToDate
              ? moment(formObject.coveragePeriodToDate).add(1, "year").toDate()
              : moment().endOf("year").add(1, "year").toDate()
          }
        />
      ) : null}
      {showReminderForm ? (
        <SendReminderFormDialog
          values={[selectedLocation]}
          close={() => {
            dispatch(setShowReminderForm(false));
          }}
        />
      ) : null}
      {showAddLocationDialog ? (
        <AddLocationDialog
          values={{}}
          close={() => {
            dispatch(hideAddLocationDialog({}));
            //dispatch(loadPropertyInfoRoutine.trigger());
          }}
        />
      ) : null}
      <Dialog />
    </>
  );
};

export default ValueMonitoring;
