import { css } from "@emotion/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { msal } from "../api/auth";
import { logout } from "../api/GraphService";
import background from "../assets/background-login.jpg";
import useAcc from "../assets/picker_account_add.svg";

export const NoAccess = () => {
  const isGOS = process.env.REACT_APP_GOS === "true";
  const { t } = useTranslation();
  return (
    <div
      css={css`
        z-index: 99;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: url(${background}) no-repeat;
        background-size: cover;
      `}
    >
      <div
        css={css`
          margin-left: auto;
          margin-right: auto;
          box-sizing: border-box;
          position: relative;
          max-width: 440px;
          width: calc(100% - 40px);
          padding: 44px;
          margin-bottom: 28px;
          background-color: #fff;
          -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          min-width: 320px;
          min-height: 338px;
          overflow: hidden;
        `}
      >
        <h2>{t("rvm.noAccess")}</h2>

        <div
          onClick={() => (isGOS ? logout() : msal.logout())}
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 5px;
            margin-top: 15px;

            &:hover {
              background: #f8f9fe;
            }

            span {
              flex: 1;
              margin-left: 10px;
            }
          `}
        >
          <img src={useAcc} />
          <span>{t("rvm.useAnotherAccount")}</span>
        </div>
      </div>
    </div>
  );
};
