import { CommandBar, FocusTrapZone } from "@fluentui/react";
import React from "react";
import useDocCommandBarItems from "../../../hooks/useDocCommandBarItems";

function ExternalUserCommandBar() {
  const commandBarItems = useDocCommandBarItems();

  return (
    <div style={{ width: "670px" }}>
      <FocusTrapZone>
        <CommandBar items={commandBarItems as any} overflowItems={[]} />
      </FocusTrapZone>
    </div>
  );
}

export default ExternalUserCommandBar;
