import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../store/hooks";
const LocationTypeTaxonomy = ({ row }) => {
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();
  var lt = row.location.locationType;
  return <>{lt!=null ? t(taxonomy.LocationType.byId[lt].code):""}</>;
};

export default LocationTypeTaxonomy;
