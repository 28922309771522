/* eslint-disable react/prop-types */
import {
  DirectionalHint,
  FontIcon,
  FontSizes,
  ImageIcon,
  Spinner,
  SpinnerSize,
  TooltipHost,
  getTheme,
  mergeStyleSets,
} from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { getFolders } from "../../api/gosApi";

import { useSelector } from "../../store/hooks";
import { VirtualFolder } from "../../types/types";
import { IDestinationLocationInfo } from "./SearchList/SearchList";
import { IconsSVG } from "./icons/icons";

export interface IListRowProps {
  itemName: string;
  itemNumber: number | string;
  itemType: string;
  itemID: number;
  partnerType?: string;
  IBOType?: string;
  isRootSelected: boolean;
  selectedVirtualFolderId?: number;
  onSelectDestination: (destination: IDestinationLocationInfo) => void;
  isSelected: boolean;
}

export const ListRow = ({
  itemName,
  itemNumber,
  itemType,
  itemID,
  partnerType,
  IBOType,
  isRootSelected,
  selectedVirtualFolderId,
  isSelected,
  onSelectDestination,
}: IListRowProps) => {
  const theme = getTheme();
  const mailConfig = useSelector((s) => s.locations.mailConfiguration);

  const [showVirtualFolders, setShowVirtualFolders] = useState<boolean>(false);
  const [folders, setFolders] = useState<VirtualFolder[]>([]);
  const [foldersView, setFoldersView] = useState<JSX.Element[]>([]);
  const [foldersFetched, setFoldersFetched] = useState<boolean>(false);

  // Show spinner while folders are fetching
  const [foldersFetching, setFoldersFetching] = useState<boolean>(false);

  const classNames: IItemListRowClassObject = mergeStyleSets({
    itemCell: [
      {
        color: theme.palette.neutralPrimary,
        padding: 10,
        boxSizing: "border-box",
        display: "flex",
        height: 36,
        selectors: {
          "&:hover": {
            background: theme.palette.neutralLighter,
            selectors: {
              ".moveto": { display: "block" },
              ".itemtitle": { width: "100%" },
            },
          },
          ".moveto": { display: "none" },
          ".itemtitle": { maxWidth: "calc(100% - 36px)" },
        },
      },
    ],
    arrowIcon: [
      {
        alignSelf: "center",
        fontSize: FontSizes.mini,
        cursor: "pointer",
        marginRight: 4,
        marginBottom: 4,
      },
    ],
    folderIcon: [
      {
        alignSelf: "center",
        cursor: "pointer",
      },
    ],
    itemTitleWrapper: [
      {
        flexGrow: 1,
        alignSelf: "center",
        fontSize: FontSizes.medium,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflowX: "hidden",
      },
    ],
    itemName: [
      {
        maxWidth: `${
          itemType === "Project" ? "calc(100% - 100px)" : "calc(100% - 74px)"
        }`, // Projects has number longer than 6 digits
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflowX: "hidden",
        display: "inline-block",
        height: "100%",
      },
    ],
    itemNumber: [
      {
        overflowX: "hidden",
        display: "inline-block",
        height: "100%",
      },
    ],
  });

  useEffect(() => {
    setShowVirtualFolders(false);
    setFolders([]);
    setFoldersView([]);
    setFoldersFetched(false);
  }, [itemID]);

  useEffect(() => {
    if (mailConfig) {
      onSelectDestination(mailConfig.settingValue);
    }
  }, [mailConfig]);

  const updateVirtualFolders = (virtualFolders: VirtualFolder[]) => {
    setFoldersView(
      virtualFolders.map((folder: VirtualFolder, index) => (
        <div
          key={`${folder.folderName} ${index}`}
          className={classNames.itemCell}
          style={{
            marginLeft: 24 * (folder.folderDepthLevel + 1) + 14,
          }}
        >
          <ImageIcon
            imageProps={{
              src: IconsSVG.folderSimplified,
              className: classNames.folderIcon,
            }}
            onClick={() => {
              const destination: IDestinationLocationInfo = {
                containerId: itemID,
                containerTypeName: itemType,
                containerName: itemName,
                partnerTypeName: partnerType,
                virtualFolderId: folder.virtualFolderID,
                IBOType,
              };
              // console.log(folder, "click2");
              onSelectDestination(destination);
            }}
            title={folder.folderName}
          />
          <div
            className={`itemtitle ${classNames.itemTitleWrapper} ${
              selectedVirtualFolderId === folder.virtualFolderID
                ? "selecteditem"
                : ""
            }`}
          >
            &nbsp; &nbsp;
            {folder.folderName}
          </div>
        </div>
      ))
    );
  };

  useEffect(() => {
    updateVirtualFolders(folders);
  }, [selectedVirtualFolderId]);

  useEffect(() => {
    if (isSelected) {
      openVirtualFolders();
    }
  }, [isSelected, itemName]);

  /**
   * Sort folders to apply structure visualisation
   * @param resFolder
   */
  const sortFolders = (resFolder: VirtualFolder[]) => {
    const foldersSorted: VirtualFolder[] = [];
    // Put the first level folders
    resFolder.forEach((folder: VirtualFolder) => {
      if (!folder.parentFolderID) {
        foldersSorted.push(folder);
      }
    });

    // Put the second level folders
    resFolder.forEach((folder: VirtualFolder) => {
      if (folder.folderDepthLevel === 1 && folder.parentFolderID) {
        const parentFolderIndex = foldersSorted.findIndex(
          (parentFolder: VirtualFolder) =>
            parentFolder.virtualFolderID === folder.parentFolderID
        );
        if (parentFolderIndex !== -1) {
          foldersSorted.splice(parentFolderIndex + 1, 0, folder);
        }
      }
    });

    // Put the third level folders
    resFolder.forEach((folder: VirtualFolder) => {
      if (folder.folderDepthLevel === 2 && folder.parentFolderID) {
        const parentFolderIndex = foldersSorted.findIndex(
          (parentFolder: VirtualFolder) =>
            parentFolder.virtualFolderID === folder.parentFolderID
        );
        if (parentFolderIndex !== -1) {
          foldersSorted.splice(parentFolderIndex + 1, 0, folder);
        }
      }
    });

    return foldersSorted;
  };

  const openVirtualFolders = () => {
    if (foldersFetched) {
      setShowVirtualFolders(true);
      return;
    }

    setFoldersFetching(true);

    getFolders({
      cid: itemID,
      ctype: itemType,
      ptype: partnerType,
    })
      .then((resFolder: VirtualFolder[]) => {
        setShowVirtualFolders(true);
        setFoldersFetching(false);
        setFoldersFetched(true);

        const foldersSorted: VirtualFolder[] = sortFolders(resFolder);

        setFolders(foldersSorted);
        updateVirtualFolders(foldersSorted);
      })
      .catch((error) => {
        // TODO Show error
        // toast.error(error.message);
        setFoldersFetching(false);
        setFoldersFetched(true);
      });
  };

  const hideVirtualFolders = () => {
    setShowVirtualFolders(false);
  };

  return (
    <>
      <div className={classNames.itemCell}>
        {!showVirtualFolders &&
          !foldersFetching &&
          itemType !== "MandateClient" &&
          itemType !== "CoopInsurer" && (
            <FontIcon
              className={classNames.arrowIcon}
              iconName="ChevronRight"
              onClick={openVirtualFolders}
            />
          )}
        {showVirtualFolders &&
          itemType !== "MandateClient" &&
          itemType !== "CoopInsurer" && (
            <FontIcon
              className={classNames.arrowIcon}
              iconName="ChevronDown"
              onClick={hideVirtualFolders}
            />
          )}
        {foldersFetching &&
          itemType !== "MandateClient" &&
          itemType !== "CoopInsurer" && (
            <Spinner
              className={classNames.arrowIcon}
              size={SpinnerSize.xSmall}
            />
          )}
        <TooltipHost
          content={itemName}
          directionalHint={DirectionalHint.bottomCenter}
          styles={{ root: classNames.folderIcon }}
        >
          <ImageIcon
            imageProps={{
              src:
                itemType === "MandateClient"
                  ? IconsSVG.corporate
                  : itemType === "CoopInsurer"
                  ? IconsSVG.insurer
                  : IconsSVG.folderSimplified,
            }}
            onClick={() => {
              const destination: IDestinationLocationInfo = {
                containerId: itemID,
                containerTypeName: itemType,
                containerName: itemName,
                partnerTypeName: partnerType,
                IBOType,
              };
              // console.log(destination, "click");
              onSelectDestination(destination);
            }}
          />
        </TooltipHost>
        <div
          className={`itemtitle ${classNames.itemTitleWrapper} ${
            isRootSelected ? "selecteditem" : ""
          }`}
        >
          <div className={classNames.itemName}>
            &nbsp; &nbsp;
            {itemName}
          </div>
          <div className={classNames.itemNumber}>
            &nbsp;
            {`[${itemNumber}]`}
          </div>
        </div>
      </div>
      {showVirtualFolders && foldersView}
    </>
  );
};

interface IItemListRowClassObject {
  itemCell: string;
  arrowIcon: string;
  folderIcon: string;
  itemTitleWrapper: string;
  itemName: string;
  itemNumber: string;
}
