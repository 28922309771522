import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api/api";
import { LoadStatus } from "../store";
import { createAsyncRoutine, handleAxiosError } from "../util";

export type ClientsPageState = {
  clientId: string;
  client: any;
  clientsLoadStatus: LoadStatus;
};

const initialState: ClientsPageState = {
  clientId: null,
  client: null,
  clientsLoadStatus: "none",
};

export const clientsPageSlice = createSlice({
  name: "clientsPage",
  initialState,
  reducers: {
    resetState: (s) => {
      Object.keys(s).forEach((key) => {
        s[key] = initialState[key];
      });
    },
    setClientId: (s, a: PayloadAction<ClientsPageState["clientId"]>) => {
      s.clientId = a.payload;
    },
    setClient: (s, a: PayloadAction<ClientsPageState["client"]>) => {
      s.client = a.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadClientInfoRoutine.loading, (s) => {
      s.clientsLoadStatus = "loading";
    });
    builder.addCase(loadClientInfoRoutine.success, (s, a) => {
      s.clientsLoadStatus = "success";
      s.client = a.payload;
    });
    builder.addCase(loadClientInfoRoutine.error, (s) => {
      s.clientsLoadStatus = "error";
    });
  },
});

export const {
  actions: { setClientId, setClient },
  reducer: clientsReducer,
} = clientsPageSlice;

export const loadClientInfoRoutine = createAsyncRoutine<
  { clientId: string },
  void,
  any[],
  any
>("clients/loadClientInfo");

function* loadClientInfoSaga() {
  yield takeEvery(
    loadClientInfoRoutine.trigger,
    function* (a: ReturnType<typeof loadClientInfoRoutine.trigger>) {
      const { clientId } = a.payload;
      try {
        yield put(loadClientInfoRoutine.loading());
        const res = yield call(API.getClientInfo, clientId);
        yield put(loadClientInfoRoutine.success(res.data));
      } catch (err) {
        yield put(loadClientInfoRoutine.error(err));
        handleAxiosError(err);
      }
    }
  );
}

export function* clientsSaga() {
  yield fork(loadClientInfoSaga);
}
