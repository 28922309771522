import moment from "moment";
import { IStyle, mergeStyleSets } from "office-ui-fabric-react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { VSpace } from "../../../components/Spacer";
import i18n from "../../../i18n";
import { useSelector } from "../../../store/hooks";
import { darkTheme, lightTheme } from "../../../utils/utils";
import LogPersona from "../history/LogPersona";

interface IStyles {
  styles?: {
    styles?: IStyle;
    container?: IStyle;
    label?: IStyle;
    date?: IStyle;
    link?: IStyle;
    personWrapper?: IStyle;
  };
}

const HistoryUsers = ({ styles }: IStyles) => {
  const { t } = useTranslation();
  const theme =
    localStorage.getItem("darkMode") === "true" ? darkTheme : lightTheme;
  const history = useSelector((s) => s.valueMonitoring.historyData);

  const firstHistory = useMemo(() => {
    if (history && history.length > 0) {
      return history[0];
    }
    return null;
  }, [history]);

  const createdAt = firstHistory ? moment(firstHistory.createdAt) : "";
  const createdBy = firstHistory ? firstHistory.createdByUserName : "";
  const modifiedAt = firstHistory ? moment(firstHistory.lastModifiedAt) : "";
  const modifiedBy = firstHistory ? firstHistory.lastModifiedByUserName : "";

  const generalStyles = mergeStyleSets({
    container: {
      fontSize: 14,
      ...(typeof styles?.container === "object" && { ...styles.container }),
    },
    label: {
      textTransform: "uppercase",
      color: theme.palette.neutralPrimary,
      ...(typeof styles?.label === "object" && { ...styles.label }),
    },
    date: {
      textTransform: "capitalize",
      ...(typeof styles?.date === "object" && { ...styles.date }),
    },
    link: {
      fontWeight: "bold",
      marginRight: 4,
      ...(typeof styles?.link === "object" && { ...styles.link }),
    },
    personWrapper: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "15px",
      ...(typeof styles?.personWrapper === "object" && {
        ...styles.personWrapper,
      }),
    },
  });

  return (
    <>
      {firstHistory ? (
        <>
          <VSpace height={50} />
          <div className={generalStyles.container}>
            {createdBy && createdAt && (
              <div>
                <label className={generalStyles.label}>
                  {t("rvm.logs.type.created")} -{" "}
                  <span className={generalStyles.date}>
                    {moment(createdAt)
                      .locale(i18n.language)
                      .format("DD. MMMM YYYY, H:mm:ss")
                      .toLowerCase()}
                  </span>
                </label>

                <div className={generalStyles.personWrapper}>
                  <LogPersona name={createdBy} />
                </div>
              </div>
            )}
            {modifiedAt && modifiedBy && (
              <div style={{ marginTop: 10 }}>
                <label className={generalStyles.label}>
                  {t("rvm.logs.type.updated")} -{" "}
                  <span className={generalStyles.date}>
                    {moment(modifiedAt)
                      .locale(i18n.language)
                      .format("DD. MMMM YYYY, H:mm:ss")}
                  </span>
                </label>

                <div className={generalStyles.personWrapper}>
                  <LogPersona name={modifiedBy} />
                </div>
              </div>
            )}
          </div>
        </>
      ) : null}
    </>
  );
};

export default HistoryUsers;
