import React from "react";

interface iconProps {
  height?: string;
  width?: string;
}

export const RamtIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : "27"}
      height={props.height ? props.height : "30"}
      viewBox="0 0 27 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.762 0H6.44453C5.79157 0 5.25732 0.534339 5.25732 1.18742V1.1959V6.37814H26.9322V1.1959C26.9407 0.542821 26.415 0.00848157 25.762 0Z"
        fill="#FF8097"
      />
      <path
        d="M26.9322 6.37891H5.25732V12.7571H26.9322V6.37891Z"
        fill="#E54E5C"
      />
      <path
        d="M7.13167 25.5128C6.09711 25.5128 5.2576 24.6731 5.2576 23.6384V15.8184C2.22176 16.675 0 19.4654 0 22.7733C0 26.7681 3.23088 29.9996 7.22496 29.9996C10.2438 29.9996 12.8387 28.1421 13.9157 25.5128H9.67567H7.13167Z"
        fill="#E54E5C"
      />
      <path
        d="M14.4503 22.7734C14.4503 23.7403 14.2553 24.6648 13.9161 25.5129H25.7372C26.3986 25.5214 26.9329 24.9871 26.9414 24.334V24.317V19.1348H13.4751C14.0941 20.2034 14.4503 21.4502 14.4503 22.7734Z"
        fill="#A30A1D"
      />
      <path
        d="M5.25732 12.7559V15.8177C5.88484 15.6396 6.54628 15.5463 7.22468 15.5463C9.8874 15.5463 12.2109 16.9882 13.466 19.134H26.9322V12.7559H5.25732Z"
        fill="#CE182E"
      />
      <path
        d="M13.4747 19.1348H5.26611V23.6385C5.26611 24.6732 6.10564 25.5129 7.1402 25.5129H13.9242C14.2719 24.6648 14.4584 23.7487 14.4584 22.7734C14.45 21.4502 14.0938 20.2034 13.4747 19.1348Z"
        fill="#A30A1D"
      />
      <path
        d="M13.4747 19.1346C12.2197 16.9887 9.89619 15.5469 7.23348 15.5469C6.5466 15.5469 5.89363 15.6402 5.26611 15.8183V19.1346H13.4747V19.1346Z"
        fill="#A30A1D"
      />
      <path
        d="M10.9054 24.9684L7.48801 19.0483C7.35233 18.8193 7.15729 18.6836 6.93681 18.6836C6.72481 18.6836 6.52129 18.8193 6.38562 19.0483L2.9682 24.9684C2.83252 25.1974 2.82404 25.4349 2.9258 25.6215C3.03604 25.8081 3.24803 25.9184 3.51091 25.9184H10.3457C10.6086 25.9184 10.8291 25.8081 10.9308 25.6215C11.0326 25.4349 11.0411 25.2059 10.9054 24.9684ZM6.93681 25.172C6.65697 25.172 6.42801 24.943 6.42801 24.6631C6.42801 24.3832 6.65697 24.1542 6.93681 24.1542C7.21665 24.1542 7.44561 24.3832 7.44561 24.6631C7.44561 24.943 7.21665 25.172 6.93681 25.172ZM7.41167 23.0007C7.41167 23.3315 7.26753 23.5181 6.93681 23.5181C6.60609 23.5181 6.46193 23.323 6.46193 23.0007L6.34322 20.9906C6.34322 20.6598 6.60609 20.3969 6.93681 20.3969C7.26753 20.3969 7.53041 20.6598 7.53041 20.9906L7.41167 23.0007Z"
        fill="#C5FDF4"
      />
    </svg>
  );
};
