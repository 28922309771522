import { css } from "@emotion/react";
import { formatISO } from "date-fns";
import { Formik } from "formik";
import { Dialog, DialogFooter, DialogType } from "office-ui-fabric-react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { FormSpinner } from "../../../components/FormSpinner";
import ToolbarButton from "../../../components/controls/ToolbarButton";
import { Field } from "../../../components/form/Field";
import useIsExternalUser from "../../../hooks/useIsExternalUser";
import useIsInternalUser from "../../../hooks/useIsInternalUser";
import { useSelector } from "../../../store/hooks";
import {
  loadPropertyInfoForClientRoutine,
  setLocation,
  updatePropertyInfoActionsRoutine,
} from "../../../store/valueMonitoring/valueMonitoringPage";
import { setYupLocale } from "../../../utils/setYupLocale";
import { formBase64String, stringToNumber } from "../../../utils/utils";

type Props = {
  close: () => void;
  values: any;
  filename: any;
  file: any;
};

const SubmitFormDialog = ({ close, values, filename, file }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const isLoading =
    useSelector((s) => s.valueMonitoring.dataLoadStatus) === "loading";
  const formObject = useSelector((s) => s.valueMonitoring.formObject);

  const isInternalUser = useIsInternalUser();
  const isExternalUser = useIsExternalUser();

  const selectedLocation = useSelector(
    (s) => s.valueMonitoring.selectedLocation
  );

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({ comment: yup.string().max(512).nullable() });
  }, [t]);

  const prepareFileForPayload = async (file) => {
    if (!file) return;
    return await {
      name: file.name,
      base64String: await formBase64String(file),
    };
  };

  const onSubmit = async (data) => {
    let reportDocument = await prepareFileForPayload(file);
    await dispatch(
      updatePropertyInfoActionsRoutine.trigger({
        clientId: formObject.clientId,
        locationId: formObject.locationId,
        propertyInfoId: formObject.propertyInformationId,
        completed: true,
        formObject: {
          reportDocument,
          currencyCode: values.currencyCode.value,
          buildingValues: stringToNumber(values.buildingValues),

          buildingValuesComment:
            values.buildingValuesComment?.value || values.buildingValuesComment,

          equipmentValues: stringToNumber(values.equipmentValues),

          equipmentValuesComment:
            values.equipmentValuesComment?.value ||
            values.equipmentValuesComment,
          stockValues: stringToNumber(values.stockValues),
          totalPd: stringToNumber(values.totalPd),
          businessInteruptionValues12Months: stringToNumber(
            values.businessInteruptionValues12Months
          ),
          totalPdBi: stringToNumber(values.totalPdBi),
          indemnityPeriodCode: values.indemnityPeriodCode?.value,
          businessInteruptionValuesBasedOnIndemnityPeriod: stringToNumber(
            values.businessInteruptionValuesBasedOnIndemnityPeriod
          ),
          businessInteruptionValues12MonthsComment:
            values.businessInteruptionValues12MonthsComment?.value ||
            values.businessInteruptionValues12MonthsComment,
          totalPdBiBasedOnIndemnityPeriod: stringToNumber(
            values.totalPdBiBasedOnIndemnityPeriod
          ),
          clientComment: isExternalUser
            ? data.comment
            : formObject.clientComment,
          grECoComment: isInternalUser ? data.comment : formObject.grECoComment,
          rowVersion: formObject.rowVersion,
          coveragePeriodFromDate: formatISO(values.coveragePeriodFromDate, {
            representation: "date",
          }),
          coveragePeriodToDate: formatISO(values.coveragePeriodToDate, {
            representation: "date",
          }),

          desiredLocationStatusCode: values.desiredLocationStatusCode.value,
          desiredLocationStatusDate: values.desiredLocationStatusDate
            ? formatISO(values.desiredLocationStatusDate, {
                representation: "date",
              })
            : "",
          desiredLocationOwningStatusCode:
            values.desiredLocationOwningStatusCode.value,
          desiredLocationStatusCodeComment:
            values.desiredLocationStatusCodeComment?.value ||
            values.desiredLocationStatusCodeComment,
          desiredLocationOwningStatusDate:
            values.desiredLocationOwningStatusDate
              ? formatISO(values.desiredLocationOwningStatusDate, {
                  representation: "date",
                })
              : "",

          documentPath: filename,
        },
        onSuccess: () => {
          dispatch(
            loadPropertyInfoForClientRoutine.trigger({
              clientId: formObject.clientId,
              onSuccess: () => {
                dispatch(
                  setLocation({
                    item: {
                      value: formObject.locationId,
                      label: selectedLocation.location.locationName,
                    },
                  })
                );
              },
            })
          );
        },
      })
    );
    close();
  };

  const title = (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      {t("rvm.submit.label")}
    </div>
  );
  return (
    <Dialog
      hidden={false}
      onDismiss={close}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        // className: cssClass`
        //   .ms-Dialog-inner {
        //     padding-bottom: 14px;
        //   }
        //   .ms-Dialog-title {
        //     padding-bottom: 10px;
        //   }
        // `,
        showCloseButton: true,
      }}
      minWidth={400}
      modalProps={{
        isBlocking: true, //phraseActivated ? false :
      }}
    >
      <Formik
        initialValues={{ comment: "" }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange={didTrySubmit}
        validateOnBlur={didTrySubmit}
      >
        {({ submitForm }) => {
          return (
            <>
              <Field
                name="comment"
                type="text"
                label={t("rvm.clientComment.label")}
                isMultiline
                rows={4}
                isRequired={false}
              />
              <DialogFooter>
                <ToolbarButton
                  iconName={"SaveAndClose"}
                  text={t("rvm.navBar.saveAndNotify")}
                  disabled={isLoading}
                  operationFn={() => {
                    setDidTrySubmit(true);
                    submitForm();
                  }}
                />
              </DialogFooter>
              {isLoading && <FormSpinner />}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default SubmitFormDialog;
