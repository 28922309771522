import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../store/hooks";
const renderCountry = (item: any, taxonomy: any, t: any): string => {
  var c = taxonomy.Country.byId[item.location.addresses[0]?.countryCodeID]?.code;
  return c?t(c):"";
};
const Country = ({ row }) => {
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();

  return <div>{renderCountry(row, taxonomy, t)}</div>;
};

export default Country;
