import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
  color?: string;
}

export const BranchOfficeIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '30'}
      height={props.height ? props.height : '30'}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.7862 11.8208L15.5446 0.193581C15.2277 -0.0645271 14.7718 -0.0645271 14.456 0.193581L0.213267 11.8208C-0.189051 12.1086 0.0157095 12.7416 0.511662 12.7416H2.41521V28.7515C2.41521 29.4408 2.97702 30 3.6695 30H15.0003H26.331C27.0235 30 27.5853 29.4408 27.5853 28.7515V12.7416H29.4889C29.9838 12.7416 30.1886 12.1086 29.7862 11.8208Z"
        fill={props.color || '#0095E1'}
      />
      <path
        d="M25.6595 11.9396H23.2507V30H26.3304C27.0228 30 27.5846 29.4408 27.5846 28.7515V12.7416H29.4882C29.9841 12.7416 30.1889 12.1096 29.7866 11.8208L15.5449 0.193581C15.228 -0.0645271 14.7722 -0.0645271 14.4563 0.193581L13.3738 1.46364"
        fill={props.color || '#0D83B2'}
      />
      <path
        d="M21.4861 17.0167C21.4861 15.9536 20.6177 15.0891 19.5496 15.0891C18.4816 15.0891 17.6132 15.9536 17.6132 17.0167C17.6132 17.875 18.1801 18.6033 18.9601 18.8522V21.1577C18.9601 22.0529 18.3838 22.8344 17.5257 23.1017L15.2785 23.8023V17.5114C16.0584 17.2626 16.6254 16.5343 16.6254 15.676C16.6254 14.6128 15.7569 13.7484 14.6889 13.7484C13.6208 13.7484 12.7524 14.6128 12.7524 15.676C12.7524 16.5343 13.3194 17.2626 14.0993 17.5114V21.2315L11.8521 20.5309C10.9939 20.2636 10.4177 19.4821 10.4177 18.5869V16.1707C11.1977 15.9218 11.7646 15.1936 11.7646 14.3353C11.7646 13.2721 10.8962 12.4077 9.82814 12.4077C8.76009 12.4077 7.89166 13.2721 7.89166 14.3353C7.89166 15.1936 8.45861 15.9218 9.23855 16.1707V18.5869C9.23855 19.9973 10.1471 21.2294 11.5002 21.6504L14.0993 22.4606V27.1588H15.2785V25.0324L17.8776 24.2223C19.2296 23.8003 20.1382 22.5691 20.1382 21.1588V18.8532C20.9192 18.6033 21.4861 17.875 21.4861 17.0167ZM9.82814 13.5814C10.2459 13.5814 10.5854 13.9194 10.5854 14.3353C10.5854 14.7511 10.2459 15.0891 9.82814 15.0891C9.41039 15.0891 9.07084 14.7511 9.07084 14.3353C9.07084 13.9194 9.41039 13.5814 9.82814 13.5814ZM14.6889 14.9222C15.1066 14.9222 15.4462 15.2602 15.4462 15.676C15.4462 16.0918 15.1066 16.4298 14.6889 16.4298C14.2711 16.4298 13.9316 16.0918 13.9316 15.676C13.9316 15.2602 14.2711 14.9222 14.6889 14.9222ZM19.5496 17.7706C19.1319 17.7706 18.7923 17.4326 18.7923 17.0167C18.7923 16.6009 19.1319 16.2629 19.5496 16.2629C19.9674 16.2629 20.3069 16.6009 20.3069 17.0167C20.3069 17.4326 19.9664 17.7706 19.5496 17.7706Z"
        fill="#5DCDF9"
      />
    </svg>
  );
};
