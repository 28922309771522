import React from 'react';

interface iconProps {
    height?: string;
    width?: string;
}

export const SubClientsIcon = (props: iconProps): JSX.Element => {
    return (
        <svg width={props.width ? props.width : '31'} height={props.height ? props.height : '30'} viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.5436 20.8545H17.6686V23.9006H37.5436V20.8545Z" fill="#D13600" />
            <path d="M37.5712 5.84277H22.8469V8.88893H37.5712V5.84277Z" fill="#D13600" />
            <path d="M37.5431 35.8926H24.8343V38.9387H37.5431V35.8926Z" fill="#D13600" />
            <path d="M25.9883 38.9356L25.9883 5.94922L22.8811 5.94922L22.8811 38.9356H25.9883Z" fill="#D13600" />
            <path d="M41.3506 16.9834H30.2094V27.9057H41.3506V16.9834Z" fill="#E15815" />
            <path d="M41.3506 2H30.2094V12.9223H41.3506V2Z" fill="#E15815" />
            <path d="M19.3479 13.7451H3V29.7718H19.3479V13.7451Z" fill="#E15815" />
            <path d="M41.3506 31.5283H30.2094V42.4506H41.3506V31.5283Z" fill="#E15815" />
        </svg>
    )
}

