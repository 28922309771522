import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useCurrentRoute } from "../../config/routes";
import { useSelector } from "../../store/hooks";

export const useDynamicConfig = (d: any) => {
  const route = useCurrentRoute();
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const taxonomy = useSelector((s) => s.taxonomy);
  const dispatch = useDispatch();

  if (!d) return {};

  return d({ route, t, values, taxonomy, dispatch });
};
