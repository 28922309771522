import { useTheme } from "@emotion/react";
import {
  IShimmerElement,
  Shimmer,
  ShimmerElementType,
} from "@fluentui/react/lib/Shimmer";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import * as React from "react";

const classes = mergeStyleSets({
  navigation: {
    height: 44,
  },
  wrapp: {
    marginTop: 50,
  },
  content: {
    maxWidth: "100%",
    paddingBottom: "20px",
    //margin: "50px auto",
  },
  contentMain: {
    paddingBottom: "30px",
  },
});

const headerSkeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "20%",
    height: 44,
  },
];

const mainFieldsSkeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "top",
    width: "33%",
    height: 77,
  },
  { type: ShimmerElementType.gap, width: "2%" },
  {
    type: ShimmerElementType.line,
    verticalAlign: "top",
    width: "33%",
    height: 77,
  },
  { type: ShimmerElementType.gap, width: "2%" },
  {
    type: ShimmerElementType.line,
    verticalAlign: "top",
    width: "33%",
    height: 77,
  },
];

const commentSkeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "top",
    width: "100%",
    height: 126,
  },
];

const formSeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "100%",
    height: 800,
  },
];

const FormSkeleton = () => {
  const theme = useTheme();
  const isDarkMode = theme.isDark;
  return (
    <div className={classes.contentMain}>
      <div className={classes.content}>
        <Shimmer
          shimmerElements={mainFieldsSkeleton}
          shimmerColors={{
            background: isDarkMode ? "#151515" : "#f8f9fe",
          }}
        />
      </div>
      <div className={classes.content}>
        <Shimmer
          shimmerElements={mainFieldsSkeleton}
          shimmerColors={{
            background: isDarkMode ? "#151515" : "#f8f9fe",
          }}
        />
      </div>
      <div className={classes.content}>
        <Shimmer
          shimmerElements={mainFieldsSkeleton}
          shimmerColors={{
            background: isDarkMode ? "#151515" : "#f8f9fe",
          }}
        />
      </div>
      <div className={classes.content}>
        <Shimmer
          shimmerElements={mainFieldsSkeleton}
          shimmerColors={{
            background: isDarkMode ? "#151515" : "#f8f9fe",
          }}
        />
      </div>
      {/* <div className={classes.content}>
        <Shimmer
          shimmerElements={commentSkeleton}
          shimmerColors={{
            background: isDarkMode ? "#151515" : "#f8f9fe",
          }}
        />
      </div> */}
    </div>
  );
};

export default FormSkeleton;
