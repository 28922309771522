import { INavLink } from "@fluentui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AnchorNav } from "../../../components/AnchorNav/AnchorNav";
import { useCurrentRoute } from "../../../config/routes";
import { handleScrollToElement } from "../../../utils/crmUtils";

function ValueMonitoringAnchorNav() {
  const { t } = useTranslation();
  const route = useCurrentRoute();

  const commandBarKey = "rvmValueMonitoringForm";

  const isEditMode = useMemo(() => {
    return route === "valueMonitoringForLocationEdit";
  }, [route]);

  const configObject = useMemo(() => {
    if (isEditMode) {
      return {
        rvmValueMonitoringForm: {
          groups: [
            {
              links: [
                {
                  name: t("rvm.general.label"),
                  url: "",
                  key: "Current",
                  ariaLabel: t("rvm.general.label"),
                },
              ],
            },
          ],
          onLinkClick: (
            ev?: React.MouseEvent<HTMLElement>,
            item?: INavLink
          ) => {
            handleScrollToElement(".rvm-form-", ev, item);
          },
          startSelectedKey: "Current",
        },
      };
    } else
      return {
        rvmValueMonitoringForm: {
          groups: [
            {
              links: [
                {
                  name: t("rvm.current.label"),
                  url: "",
                  key: "Current",
                  ariaLabel: t("rvm.current.label"),
                },
                {
                  name: t("rvm.history.label"),
                  url: "",
                  key: "History",
                  ariaLabel: t("rvm.history.label"),
                },
              ],
            },
          ],
          onLinkClick: (
            ev?: React.MouseEvent<HTMLElement>,
            item?: INavLink
          ) => {
            handleScrollToElement(".rvm-form-", ev, item);
          },
          startSelectedKey: "Current",
        },
      };
  }, [t, isEditMode]);

  return (
    <AnchorNav
      groups={configObject[commandBarKey].groups}
      onLinkClick={configObject[commandBarKey].onLinkClick}
      startSelectedKey={configObject[commandBarKey].startSelectedKey}
    />
  );
}

export default ValueMonitoringAnchorNav;
