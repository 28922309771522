import styled from "@emotion/styled";
import React from "react";
import grecoLogo from "../../assets/GrECo_Logo_small.jpg";
import { darkTheme, lightTheme } from "../../utils/utils";
import { UserPersona } from "./UserPersona";

const theme =
  localStorage.getItem("darkMode") === "true" ? darkTheme : lightTheme;

export const AppHeader = () => {
  return (
    <Root>
      <GrecoLogo src={grecoLogo} alt="GrECo logo" />
      <MenuContainer>GOS | Risks Value Monitoring</MenuContainer>
      <UserPersona />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.palette.themePrimary};
  height: 50px;
  padding-left: 5px;
`;

const GrecoLogo = styled.img`
  width: 40px;
  margin-right: 10px;
`;
const MenuContainer = styled.div`
  margin-right: auto;
  font-weight: 600;
  color: ${theme.palette.white};
`;
