export const constants = {};
export const statusColors = {
  "PropertyInformationStatus.NO_DATA": {
    color: "#888888",
    shape: "CircleRing",
  },
  "PropertyInformationStatus.DONE": { color: "#008800", shape: "CircleFill" },
  "PropertyInformationStatus.INVITATION_SENT": {
    color: "#ff0000",
    shape: "CircleFill",
  },
  "PropertyInformationStatus.REVIEW": { color: "#FFA500", shape: "CircleFill" },
  "PropertyInformationStatus.ARCHIVED": {
    color: "#0000ff",
    shape: "CircleFill",
  },
  "PropertyInformationStatus.DISABLED": {
    color: "#888888",
    shape: "CircleFill",
  },
};
