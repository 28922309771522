import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import React, { ReactChild, ReactChildren } from "react";
import { useTranslation } from "react-i18next";
import { darkTheme, lightTheme } from "../../../utils/utils";

interface IProps {
  label: string;
  children: ReactChild | ReactChildren;
  fieldsUpdatedByExternalUser: string;
  fieldName: string;
}
const theme =
  localStorage.getItem("darkMode") === "true" ? darkTheme : lightTheme;

const romClasses = mergeStyleSets({
  keyText: {
    marginRight: "6px",
    whiteSpace: "nowrap",
  },
  keyValueWrap: {
    marginBottom: "10px",
    display: "grid",
    gridTemplateColumns: "33% 66%",
    wordBreak: "break-word",
  },
  valueItem: {
    wordBreak: "break-word",
    fontWeight: "600",
  },
  flex: {
    display: "flex",
  },
  isMarked: {
    backgroundColor: theme.palette.yellowLight,
    color: "black",
    display: "initial",
  },
});
const ReadOnlyValuePair = ({
  label,
  children,
  fieldsUpdatedByExternalUser,
  fieldName,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <div className={`${romClasses.keyValueWrap}`}>
      <span className={`${romClasses.keyText}`}>
        <p
          id={fieldName + "_field"}
          className={`${
            fieldsUpdatedByExternalUser &&
            fieldsUpdatedByExternalUser.split(",").includes(fieldName)
              ? romClasses.isMarked
              : ""
          }`}
        >
          {t(label)}
        </p>
      </span>
      <span className={romClasses.valueItem}>
        {" "}
        <p
          id={fieldName + "_value"}
          className={`${
            fieldsUpdatedByExternalUser &&
            fieldsUpdatedByExternalUser.split(",").includes(fieldName)
              ? romClasses.isMarked
              : ""
          }`}
        >
          {children}
        </p>
      </span>
    </div>
  );
};

export default ReadOnlyValuePair;
