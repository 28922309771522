import { ContextualMenu } from 'office-ui-fabric-react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SidebarIconComponent = (item: any) => {
  const [show, setShow] = useState<boolean>(true);
  const iconRef = useRef(null);
  const { t } = useTranslation();
  return (
    <div
      className={item.itemClassName}
      title={item.item.title}
      onClick={item.item.onClick}
      ref={iconRef}
      onContextMenu={(e) => {
        setShow(false);
        e.preventDefault();
      }}
    >
      <div style={{ height: '44px', display: 'flex', alignItems: 'center' }}>
        {item.item.icon()}
      </div>
      <ContextualMenu
        items={[
          {
            key: 'newItem',
            text: t('clm.openNewTab.contextMenu.label'),
            onClick: () => {
              item.item.openInNewTab();
            }
          }
        ]}
        hidden={show}
        target={iconRef}
        onDismiss={() => setShow(true)}
        onItemClick={() => setShow(true)}
      />
    </div>
  );
};

export default SidebarIconComponent;
