export const getEnteredValuesChangesConfig = (t) => ({
  equalMessage: t("rvm.valuesChanges.equalMessage"),
  buildings: {
    decreased: {
      message: t("rvm.buildingValuesDecreased.label"),
      options: [
        {
          label: t("rvm.buildingValuesDecreasedOption1.label"),
          value: "Demolition of parts",
        },
        {
          label: t("rvm.buildingValuesDecreasedOption2.label"),
          value: "Parts were sold",
        },
      ],
    },
    increased: {
      message: t("rvm.buildingValuesIncreased.label"),
      options: [
        {
          label: t("rvm.buildingValuesIncreasedOption1.label"),
          value: "Construction work was done",
        },
        {
          label: t("rvm.buildingValuesIncreasedOption2.label"),
          value: "New building",
        },
        {
          label: t("rvm.buildingValuesIncreasedOption3.label"),
          value: "Bought the building",
        },
      ],
    },
  },
  equipment: {
    decreased: {
      message: t("rvm.equipmentValuesDecreased.label"),
      options: [
        {
          label: t("rvm.equipmentValuesDecreasedOption1.label"),
          value: "Parts were sold",
        },
        {
          label: t("rvm.equipmentValuesDecreasedOption2.label"),
          value: "Production partly closed",
        },
      ],
    },
    increased: {
      message: t("rvm.equipmentValuesIncreased.label"),
      options: [
        {
          label: t("rvm.equipmentValuesIncreasedOption1.label"),
          value: "New machinery/equipment was bought",
        },
      ],
    },
  },
  businessInterruption: {
    decreased: {
      message: t("rvm.businessInteruptionValues12MonthsDecreased.label"),
      options: [
        {
          label: t(
            "rvm.businessInteruptionValues12MonthsDecreasedOption1.label"
          ),
          value: "Bad business year",
        },
      ],
    },
    increased: {
      message: t("rvm.businessInteruptionValues12MonthsIncreased.label"),

      options: [
        {
          label: t(
            "rvm.businessInteruptionValues12MonthsIncreasedOption1.label"
          ),
          value: "Good business year",
        },
      ],
    },
  },
  finallyClosedLocationStatus: {
    message: t("rvm.locationFinallyClosedMessage.label"),
    options: [
      {
        label: t("rvm.locationFinallyClosedOption1.label"),
        value: "Building/Equipment is still owned",
      },
      {
        label: t("rvm.locationFinallyClosedOption2.label"),
        value: "Stocks continue to be stored",
      },
    ],
  },
  soldLocationStatus: {
    message: t("rvm.locationSoldMessage.label"),
    options: [],
  },
  notExistingLocationStatus: {
    message: t("rvm.locationNotExistingMessage.label"),
    options: [],
  },
});

export const changeValuesFields = {
  buildings: "buildings",
  equipment: "equipment",
  businessInterruption: "businessInterruption",
  buildingComment: "buildingValuesComment",
  equipmentComment: "equipmentValuesComment",
  businessInterruptionComment: "businessInteruptionValues12MonthsComment",
};

export const soldTaxonomyValue = 900000000009917;
export const finallyClosedTaxonomyValue = 900000000009916;
export const notExistingAnymoreTaxonomyValue = 900000000009918;
