import React, { useEffect, useState } from "react";
import { mergeStyles } from "react-select";

import { useTheme } from "@emotion/react";
import { mergeStyleSets } from "office-ui-fabric-react";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { getSelectStyles } from "../../utils/utils";
import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (inputValue: string) => ({
  label: inputValue,
  value: inputValue,
});

type Props = {
  setFieldValue: (field: string, value: any) => void;
  defaultOptions: Option[];
  defaultValue?: Option;
  name: string;
  label: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  isMarked?: boolean;
};

export const CreatebleDropdown = ({
  setFieldValue,
  defaultOptions,
  defaultValue,
  name,
  label,
  error,
  disabled,
  required,
  isMarked,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDarkMode = theme.isDark;

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const [value, setValue] = useState<Option | null>();

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setValue(newOption);
    }, 1000);
  };

  const classes = getClassNames(theme, required, disabled);

  useEffect(() => {
    setFieldValue(name, value);
  }, [name, setFieldValue, value]);

  useEffect(() => {
    setValue(defaultValue);
    setFieldValue(name, defaultValue);
  }, [name, defaultValue, setFieldValue]);

  useEffect(() => {
    setFieldValue(name, "");
    setOptions(defaultOptions);
    setValue(null);
  }, [name]);

  return (
    <div className={classes.root}>
      <FieldContainer isTooltipHidden={disabled} tooltipText={label}>
        <label
          id="aria-label"
          htmlFor="aria-example-input"
          className={classes.label}
        >
          {t(label)}
          {required && <span className={classes.required}>*</span>}
        </label>

        <CreatableSelect
          styles={mergeStyles(getSelectStyles(!!error, theme), {
            menuPortal: (p) => ({
              ...p,
              background: theme.palette.neutralLighter,
              zIndex: 9999999,
            }),
            menu: (p) => ({
              ...p,
              zIndex: 9999,
              background: theme.palette.neutralLighter,
            }),
            control: (p, state) => {
              return {
                ...p,
                background: state.isDisabled ? "transparent" : "transparent",
                // ...(isMarked && { background: theme.palette.yellowLight }),
                borderBottom: error
                  ? `2px solid rgb(164, 38, 44) !important`
                  : state.isFocused
                  ? `2px solid rgb(0, 90, 161) !important`
                  : `1px solid ${theme.palette.black} !important`,
                boxShadow: "none",
                ...(state.isDisabled && {
                  borderBottom: `1px solid ${theme.palette.neutralLighter} !important`,
                }),
              };
            },
            option: (p, state) => ({
              ...p,
              background:
                state.isSelected || state.isFocused
                  ? theme.palette.neutralLighter
                  : theme.palette.white,
              "&:hover": {
                background: theme.palette.neutralLighter,
              },
              color: theme.palette.black,
              fontSize: "14px",
              fontWeight: 400,
            }),
            input: (p) => ({
              ...p,
              color: (function () {
                if (disabled) {
                  return theme.palette.neutralSecondaryAlt;
                }
                if (isDarkMode) {
                  if (isMarked) {
                    return "black";
                  } else {
                    return "white";
                  }
                }
                return theme.palette.black;
              })(),
            }),
            singleValue: (p, state) => ({
              ...p,
              color: state.isDisabled
                ? theme.palette.neutralSecondaryAlt
                : theme.palette.black,
              fontSize: "14px",
              fontWeight: 400,
            }),
            dropdownIndicator: (p, state) => ({
              ...p,
              ...(state.isDisabled && { visibility: "hidden" }),
            }),
            indicatorSeparator: (p, state) => ({
              ...p,
              ...(state.isDisabled && { visibility: "hidden" }),
            }),
          })}
          isClearable
          isDisabled={isLoading}
          isLoading={isLoading}
          onChange={(newValue: any) => setValue(newValue)}
          onCreateOption={handleCreate}
          options={options}
          placeholder="Select or Create New"
          value={value}
        />
        {error && <ErrorMsg id={name + "_error"}>{error}</ErrorMsg>}
      </FieldContainer>
    </div>
  );
};

const getClassNames = (theme, required, disabled) =>
  mergeStyleSets({
    root: {
      width: "100%",
      marginTop: "2px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "600",
      color: disabled ? theme.palette.neutralSecondaryAlt : theme.palette.black,
      padding: "5px",
    },
    required: {
      color: "rgb(164, 38, 44)",
      fontSize: 14,
      marginLeft: 3,
    },
  });
