import React from "react";
import { Truncate } from "../../../components/styled";
import { formatNumber } from "../../../utils/number";
import { formatDateWithMoment } from "../../../utils/utils";
import Country from "../columns/Country";
import LocationName from "../columns/LocationName";
import LocationTypeTaxonomy from "../columns/LocationTypeTaxonomy";
import { ContextButton } from "./ContextButton";
import NotificationIconsCell from "./NotificationIconsCell";
import { ServiceStatusCircle } from "./ServiceStatusCircle";

const renderAddress = (item: any): string => {
  const address = item.location.addresses[0];
  const firstPart = address
    ? `${address.street ? address.street + " " : ""}${
        address.house ? address.house + " " : ""
      }${
        address.buildingFloorDoor ? address.buildingFloorDoor + " " : ""
      }`.trim()
    : "";
  let separator = "";
  if (firstPart && firstPart.length > 0) separator = ", ";
  const secondPart = address
    ? `${address.zipCode ? address.zipCode + " " : ""}${
        address.city ? address.city + " " : ""
      }`.trim()
    : "";
  return `${firstPart}${firstPart !== "" ? separator : ""}${secondPart}`;
};

export const defaultColumns: any[] = [
  {
    key: "location.statusCode",
    isResizable: true,
    fieldName: "statusCode",
    labelKey: "rvm.statusCode.label",
    name: "rvm.statusCode.label",
    maxWidth: 50,
    minWidth: 50,
    isVisible: true,
    isSortable: true,
    isSorted: false,
    isSortedDescending: true,

    isFixed: true,
    filter: {
      type: "taxonomy",
      taxonomyKey: "PropertyInformationStatus",
      value: [],
      renderLabel: ({ taxonomy, t }) => {
        const currentTheme = localStorage.getItem("darkMode");
        let color;

        if (currentTheme === "true") {
          color = "white";
        } else {
          color = "black";
        }
        return (
          <>
            <Truncate>{t(taxonomy?.code)}</Truncate>
          </>
        );
      },
    },

    onRender: (row) => {
      if (!row) return;
      return (
        <ServiceStatusCircle
          name={row.location.statusCode}
          item={row}
          tooltipHostStyles={{
            root: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          }}
          closeDelay={200}
        />
      );
    },
  },
  {
    key: "statusDate",
    isResizable: true,
    minWidth: 50,
    maxWidth: 100,
    isRowHeader: true,
    fieldName: "statusDate",
    labelKey: "rvm.statusDate.label",
    name: "rvm.statusDate.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isFixed: true,
    onRender: (row) => {
      if (!row) return null;
      return (
        <div>
          {row.statusDate ? formatDateWithMoment(row.statusDate) : null}
        </div>
      );
    },
    isSorted: false,
    isSortedDescending: true,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },

  {
    key: "location.locationName",
    isResizable: true,
    minWidth: 200,
    maxWidth: 400,
    isRowHeader: true,
    fieldName: "locationName",
    labelKey: "rvm.location.label",
    name: "rvm.location.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isFixed: true,
    onRender: (row) => {
      if (!row) return null;
      return <LocationName row={row} />;
    },
    isSorted: false,
    isSortedDescending: true,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "options",
    isResizable: true,
    fieldName: "options",
    labelKey: "greco.options",
    name: "",
    minWidth: 20,
    maxWidth: 20,
    isVisible: true,
    isFixed: true,
    isPresentInColumnOptions: false,
    isSortable: false,
    excel: {
      isVisible: false,
      isSortable: false,
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <span className="action-icon">
          <ContextButton row={row} />
        </span>
      );
    },
  },
  {
    key: "insuranceLineIcons",
    isResizable: true,
    fieldName: "insuranceLineIcons",
    labelKey: "",
    name: "",
    minWidth: 100,
    maxWidth: 100,
    isVisible: true,
    isFixed: true,
    isPresentInColumnOptions: false,
    isSortable: false,
    excel: {
      isVisible: false,
      isResizable: true,
      isSortable: false,
    },
    onRender: (row) => {
      if (!row) return;
      return <NotificationIconsCell row={row} />;
    },
  },
  {
    key: "coveragePeriodFromDate",
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    isRowHeader: true,
    fieldName: "coveragePeriodFromDate",
    labelKey: "rvm.coveragePeriodFromDate.label",
    name: "rvm.coveragePeriodFromDate.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isFixed: true,
    onRender: (row) => {
      if (!row) return null;
      return (
        <div>
          {row.coveragePeriodFromDate
            ? formatDateWithMoment(row.coveragePeriodFromDate)
            : null}
        </div>
      );
    },
    isSorted: false,
    isSortedDescending: true,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "coveragePeriodToDate",
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    isRowHeader: true,
    fieldName: "coveragePeriodToDate",
    labelKey: "rvm.coveragePeriodToDate.label",
    name: "rvm.coveragePeriodToDate.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isFixed: true,
    onRender: (row) => {
      if (!row) return null;
      return (
        <div>
          {row.coveragePeriodToDate
            ? formatDateWithMoment(row.coveragePeriodToDate)
            : null}
        </div>
      );
    },
    isSorted: false,
    isSortedDescending: true,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "location.locationType",
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    isRowHeader: true,
    fieldName: "locationType",
    labelKey: "rvm.locationType.label",
    name: "rvm.locationType.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isFixed: true,
    onRender: (row) => {
      if (!row) return null;
      return (
        <div>
          <LocationTypeTaxonomy row={row} />
        </div>
      );
    },
    isSorted: false,
    isSortedDescending: true,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "location.divisionName",
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    isRowHeader: true,
    fieldName: "divisionName",
    labelKey: "rvm.division.label",
    name: "rvm.division.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isFixed: true,
    onRender: (row) => {
      if (!row) return null;
      return <div>{row.location.divisionName}</div>;
    },
    isSorted: false,
    isSortedDescending: true,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "location.companyCode",
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    isRowHeader: true,
    fieldName: "companyCode",
    labelKey: "rvm.companyCode.label",
    name: "rvm.companyCode.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isFixed: true,
    onRender: (row) => {
      if (!row) return null;
      return <div>{row.location.companyCode}</div>;
    },
    isSorted: false,
    isSortedDescending: true,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "location.address",
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    isRowHeader: true,
    fieldName: "address",
    labelKey: "rvm.address.label",
    name: "rvm.address.label",
    isVisible: true,
    isSortable: false,
    isFixed: true,
    onRender: (row) => {
      if (!row) return null;
      return <div>{renderAddress(row)}</div>;
    },
    isSorted: false,
    isSortedDescending: true,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "country",
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    isRowHeader: true,
    fieldName: "country",
    labelKey: "rvm.country.label",
    name: "rvm.country.label",
    isVisible: true,
    isSortable: false,
    isFixed: true,
    onRender: (row) => {
      if (!row) return null;
      return <Country row={row} />;
    },
    isSorted: false,
    isSortedDescending: true,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "totalPd",
    isResizable: true,
    minWidth: 50,
    maxWidth: 100,
    isRowHeader: true,
    fieldName: "totalPd",
    labelKey: "rvm.totalPd.label",
    name: "rvm.totalPd.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isFixed: true,
    onRender: (row) => {
      if (!row) return null;
      return <div>{row.totalPd ? formatNumber(row.totalPd) : null}</div>;
    },
    isSorted: false,
    isSortedDescending: true,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "totalPdBi",
    isResizable: true,
    minWidth: 50,
    maxWidth: 100,
    isRowHeader: true,
    fieldName: "totalPdBi",
    labelKey: "rvm.totalPdBi.label",
    name: "rvm.totalPdBi.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isFixed: true,
    onRender: (row) => {
      if (!row) return null;
      return <div>{row.totalPdBi ? formatNumber(row.totalPdBi) : null}</div>;
    },
    isSorted: false,
    isSortedDescending: true,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
  {
    key: "totalPdBiBasedOnIndemnityPeriod",
    isResizable: true,
    minWidth: 50,
    maxWidth: 100,
    isRowHeader: true,
    fieldName: "totalPdBiBasedOnIndemnityPeriod",
    labelKey: "rvm.totalPdBiBasedOnIndemnityPeriod.label",
    name: "rvm.totalPdBiBasedOnIndemnityPeriod.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isFixed: true,
    onRender: (row) => {
      if (!row) return null;
      return (
        <div>
          {row.totalPdBiBasedOnIndemnityPeriod
            ? formatNumber(row.totalPdBiBasedOnIndemnityPeriod)
            : null}
        </div>
      );
    },
    isSorted: false,
    isSortedDescending: true,
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
  },
];
