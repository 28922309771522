import { css } from "@emotion/react";
import { Formik } from "formik";
import { Dialog, DialogFooter, DialogType } from "office-ui-fabric-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ToolbarButton from "../../../components/controls/ToolbarButton";
import { Field } from "../../../components/form/Field";
import { FormSpinner } from "../../../components/FormSpinner";
import { useSelector } from "../../../store/hooks";
import {
  deleteLocationRoutine,
  loadLocationsForClientRoutine,
} from "../../../store/locations/locations";

type Props = {
  close: () => void;
  values: any;
};

const DeleteLocationDialog = ({ close, values }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const clientIdBasic = useSelector((s) => s.clients.clientId);
  const clientId = useSelector((s) => s.locations.deleteDialog.clientId);
  const locationId = useSelector((s) => s.locations.deleteDialog.locationId);
  const rowVersion = useSelector((s) => s.locations.deleteDialog.rowVersion);
  const isLoading = useSelector((s) => s.locations.deleteDialog.isLoading);

  const onSubmit = async (data) => {
    await dispatch(
      deleteLocationRoutine.trigger({
        clientId: clientId,
        locationId: locationId,
        rowVersion: rowVersion,
        comment: data.comment,
        onSuccess: () => {
          dispatch(
            loadLocationsForClientRoutine.trigger({ clientId: clientIdBasic })
          );
        },
      })
    );
    close();
  };

  const title = (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      {t("rvm.delete.label")}
    </div>
  );

  return (
    <Dialog
      hidden={false}
      onDismiss={close}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        // className: cssClass`
        //   .ms-Dialog-inner {
        //     padding-bottom: 14px;
        //   }
        //   .ms-Dialog-title {
        //     padding-bottom: 10px;
        //   }
        // `,
        showCloseButton: true,
      }}
      minWidth={400}
      modalProps={{
        isBlocking: true, //phraseActivated ? false :
      }}
    >
      <Formik
        initialValues={{ comment: "" }}
        onSubmit={onSubmit}
        validateOnChange={didTrySubmit}
        validateOnBlur={didTrySubmit}
      >
        {({ submitForm }) => {
          return (
            <>
              <Field
                name="comment"
                type="text"
                label={t("rvm.clientComment.label")}
                isMultiline
                rows={4}
                isRequired={false}
              />
              <DialogFooter>
                <ToolbarButton
                  iconName={"PageRemove"}
                  text={t("rvm.delete.label")}
                  disabled={isLoading}
                  operationFn={() => {
                    setDidTrySubmit(true);
                    submitForm();
                  }}
                />
              </DialogFooter>
              {isLoading && <FormSpinner />}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default DeleteLocationDialog;
