import { useTheme } from "@emotion/react";
import { mergeStyleSets, Text, TextField } from "office-ui-fabric-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Rifm } from "rifm";
import * as Float from "../../components/use-number-format/float";
import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";

const getTextFieldStyle = (transparent, theme, isMarked) => ({
  root: {
    ".ms-TextField-fieldGroup": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
      background: transparent ? "transparent" : "inherit",
      ...(isMarked && { background: theme.palette.yellowLight }),
    },
    ".ms-TextField-fieldGroup::after": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
    },
    ".ms-TextField-fieldGroup input": {
      ...(isMarked && theme.isDark && { color: "black" }),
    },
    ".ms-TextField-fieldGroup input:disabled": {
      background: transparent ? "transparent" : "inherit",
    },
  },
});

const DoubleNumberInputField = ({
  disabled,
  label,
  required = false,
  name,
  error = "",
  value,
  onChange,
  setFieldValue = () => {},
  isNegativeAllowed = false,
  errors,
  transparent,
  isMarked,
  handleBlur,
}: any) => {
  const theme = useTheme() as any;

  const isDarkMode = theme.isDark;
  const { t } = useTranslation();

  const styles = getTextFieldStyle(transparent, theme, isMarked);
  const classes = mergeStyleSets({
    label: {
      fontSize: "14px",
      fontWeight: "600",
      padding: "5px",
      display: "block",
      color: (function () {
        if (disabled) {
          return theme.palette.neutralSecondaryAlt;
        }
        if (isDarkMode) {
          return "white";
        }
        return theme.palette.black;
      })(),
    },
    labelAsterix: {
      color: "rgb(164, 38, 44)",
    },
  });

  const onRenderLabel = () => (
    <Text className={classes.label}>
      {t(label)}

      <Text className={classes.labelAsterix}>{required && " *"}</Text>
    </Text>
  );

  const formatValue = (value) => {
    return Float.format({
      value,
      isNegativeAllowed: isNegativeAllowed,
      max: Infinity,
    }).toString();
  };

  useEffect(() => {
    if (value) {
      const lastChar = value.slice(-1);

      if (
        !Number(lastChar) &&
        lastChar !== "," &&
        lastChar.toString() !== "0"
      ) {
        const numberValue = value.substring(0, value.length - 1);
        setFieldValue(name, numberValue);
      }
    }
  }, [value]);

  return (
    <FieldContainer isTooltipHidden={disabled} tooltipText={label}>
      <Rifm
        accept={Float.accept}
        format={(v) => formatValue(v)}
        value={value}
        onChange={(value) => setFieldValue(name, formatValue(value))}
      >
        {({ value }) => (
          <>
            <TextField
              onBlur={handleBlur}
              type="tel"
              name={name}
              styles={styles}
              disabled={disabled}
              onRenderLabel={onRenderLabel}
              label={t(label)}
              onChange={onChange}
              value={value}
              errorMessage={errors[name] ? " " : undefined}
              autoComplete="off"
            />
            {errors[name] && (
              <ErrorMsg id={name + "_error"}>{errors[name]}</ErrorMsg>
            )}
          </>
        )}
      </Rifm>{" "}
    </FieldContainer>
  );
};

export default DoubleNumberInputField;
