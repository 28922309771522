import { IconButton } from "@fluentui/react";
import * as React from "react";
import { useEffect } from "react";
import { TreeMenuItem } from "react-simple-tree-menu";
import { IconsSVG } from "./icons/icons";

const SectionTreeItem: React.FunctionComponent<TreeMenuItem> = (props) => {
  // const mailConfig = useSelector((s) => s.locations.mailConfiguration);
  // const keyValues = props?.parent?.split("/");
  // const parent = keyValues[keyValues.length - 1] || props.parent;

  const returnIconPath = () =>
    props.sectionType === "Branch"
      ? IconsSVG.branchOffice
      : props.sectionType === "Contact"
      ? IconsSVG.contactPerson
      : props.sectionType === "Department"
      ? IconsSVG.department
      : props.module === "insurers"
      ? IconsSVG.insurer
      : props.module === "insurerCoops"
      ? IconsSVG.cooperation
      : props.module === "clients"
      ? IconsSVG.corporate
      : props.module === "mandates"
      ? IconsSVG.mandate
      : props.module === "intermediaries"
      ? IconsSVG.intermediary
      : IconsSVG.folderSimplified;

  useEffect(() => {
    props.hasNodes && props.toggleNode && props.toggleNode();
    return () => {
      props.hasNodes && props.toggleNode && props.toggleNode();
    };
  }, [props.hasNodes]);

  // useEffect(() => {
  //   if (
  //     mailConfig?.settingValue &&
  //     parent.toString() === mailConfig?.settingValue?.containerId.toString()
  //   ) {
  //     props.hasNodes && props.toggleNode && props.toggleNode();
  //     !props.nodesFetched &&
  //       props.fetchNodes &&
  //       props.fetchNodes().then(() => {
  //         props.updateData();
  //       });
  //   }
  // }, [mailConfig]);

  return (
    <li
      className="tree-list__item"
      style={{
        paddingLeft: `${
          props.hasNodes || !props.nodesFetched
            ? `${20 * props.level}px`
            : `${20 * props.level + 20}px`
        }`,
      }}
      onClick={props.onClick}
    >
      {(props.hasNodes || !props.nodesFetched) && (
        <div
          onClick={(e) => {
            // console.log(e, "CLICK ON TREE ITEM");
            props.hasNodes && props.toggleNode && props.toggleNode();
            !props.nodesFetched &&
              props.fetchNodes &&
              props.fetchNodes().then(() => {
                props.updateData();
              });
            e.stopPropagation();
          }}
        >
          <IconButton
            style={{
              background: "transparent",
              transform: props.isOpen ? "rotate(90deg)" : "",
              transition: "transform 0.3s ease 0s, opacity 0.3s ease 0s",
            }}
            className="icon-arrow"
            iconProps={{
              iconName: "ChevronRight",
            }}
          />
        </div>
      )}

      <img
        src={returnIconPath()}
        alt="icon"
        style={{
          height: "20px",
          marginRight: "5px",
        }}
        title={props.label}
      />
      <span className={`${props.activeKey ? "link link--bold" : "link"}`}>
        {props.label}
      </span>
    </li>
  );
};

export default SectionTreeItem;
