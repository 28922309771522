import React from "react";
import { useTranslation } from "react-i18next";
import { Flex } from "../../../components/styled";
import DocumentDropzone from "./DocumentDropzone";
import DocumentInputField from "./DocumentInputField";
import { sectionClasses } from "./DocumentList";
import { IDocument } from "./types";

type Props = {
  externalDocuments: IDocument[];
  internalDocuments: IDocument[];
  handleDeleteDocument: (url: string, internalDocType: boolean) => void;
  handleSetConfigurationSettings: (settings) => void;
  handleUpdateConfigurationSettings: () => void;
};

function ExternalDocuments({
  externalDocuments,
  internalDocuments,
  handleDeleteDocument,
  handleSetConfigurationSettings,
  handleUpdateConfigurationSettings,
}: Props) {
  const { t } = useTranslation();
  return (
    <div>
      <div className={sectionClasses.sectionWrap}>
        <div className={sectionClasses.sectionTitle}>
          {t("rvm.externalDocuments.label")}
        </div>

        {externalDocuments?.map((doc, index) => {
          return (
            <Flex
              key={`${doc.Url} ${index}`}
              justifyContent="space-between"
              gap={20}
              alignItems="center"
            >
              <DocumentInputField
                handleDelete={() => {
                  handleDeleteDocument(doc.Url, false);
                }}
                handleUpdateConfigurationSettings={
                  handleUpdateConfigurationSettings
                }
                defaultValue={doc.Name}
                handleChange={(event) => {
                  const value = event.target.value;
                  const ExternalUserDocuments = externalDocuments.map(
                    (item) => {
                      if (item.Url === doc.Url) {
                        return {
                          Name: value,
                          Url: item.Url,
                        };
                      }
                      return item;
                    }
                  );
                  handleSetConfigurationSettings({
                    settingValue: {
                      ExternalUserDocuments,
                      InternalUserDocuments: internalDocuments,
                    },
                  });
                }}
              />
            </Flex>
          );
        })}
        <div>
          <DocumentDropzone
            handleSetConfigurationSettings={handleSetConfigurationSettings}
            isInternalUserDocument={false}
          />
        </div>
      </div>
    </div>
  );
}

export default ExternalDocuments;
