import { css } from "@emotion/css";
import { useTheme } from "@emotion/react";
import {
  CheckboxVisibility,
  IColumn,
  Icon,
  SelectionMode,
} from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IObjectWithKey } from "@fluentui/react";
import { useDispatch } from "react-redux";
import { List } from "../../../../components/List/List";
import { useSelector } from "../../../../store/hooks";
import { AppDispatch } from "../../../../store/store";
import { setFormObject } from "../../../../store/valueMonitoring/valueMonitoringPage";
import { formatNumber } from "../../../../utils/number";
import { formatDateWithMoment } from "../../../../utils/utils";
import HistoryComments from "./HistoryComments";

const HistoryTable = () => {
  const dispatch: AppDispatch = useDispatch();

  const [columns, setColumns] = useState<any[]>([]);
  const [selection, setSelection] = useState<IObjectWithKey[]>([]);

  const history = useSelector((s) => s.valueMonitoring.historyData);
  const theme = useTheme();
  const { t } = useTranslation();
  const dataLoadStatus = useSelector((s) => s.valueMonitoring.dataLoadStatus);
  const taxonomy = useSelector((s) => s.taxonomy);

  useEffect(() => {
    let items: any[] = [
      {
        key: "coveragePeriodFromDate",
        name: t("rvm.coveragePeriodFromDate.label"),
        fieldName: "coveragePeriodFromDate",
        label: t("rvm.coveragePeriodFromDate.label"),
        isVisible: true,
        isSortedDescending: false,
        isSorted: false,
        minWidth: 175,
        maxWidth: 175,
        onRender: (item?: any, index?: number, column?: IColumn) => {
          return (
            <div>
              {item.coveragePeriodFromDate
                ? formatDateWithMoment(item.coveragePeriodFromDate)
                : null}
            </div>
          );
        },
      },

      {
        key: "coveragePeriodToDate",
        name: t("rvm.coveragePeriodToDate.label"),
        fieldName: "coveragePeriodToDate",
        label: t("rvm.coveragePeriodToDate.label"),
        isVisible: true,
        isSortedDescending: false,
        isSorted: false,
        minWidth: 175,
        maxWidth: 175,
        onRender: (item?: any, index?: number, column?: IColumn) => {
          return (
            <div>
              {item.coveragePeriodToDate
                ? formatDateWithMoment(item.coveragePeriodToDate)
                : null}
            </div>
          );
        },
      },

      {
        key: "totalPd",
        name: t("rvm.totalPd.label"),
        fieldName: "totalPd",
        label: t("rvm.totalPd.label"),
        isVisible: true,
        isSortedDescending: false,
        isSorted: false,
        minWidth: 100,
        maxWidth: 100,
        onRender: (item?: any, index?: number, column?: IColumn) => {
          return <div>{formatNumber(item.totalPd)}</div>;
        },
      },
      {
        key: "totalPdBi",
        name: t("rvm.totalPdBi.label"),
        fieldName: "totalPdBi",
        label: t("rvm.totalPdBi.label"),
        isVisible: true,
        isSortedDescending: false,
        isSorted: false,
        minWidth: 100,
        maxWidth: 100,
        onRender: (item?: any, index?: number, column?: IColumn) => {
          return <div>{formatNumber(item.totalPdBi)}</div>;
        },
      },
      {
        key: "totalPdBiBasedOnIndemnityPeriod",
        name: t("rvm.totalPdBiBasedOnIndemnityPeriod.label"),
        fieldName: "totalPdBiBasedOnIndemnityPeriod",
        label: t("rvm.totalPdBiBasedOnIndemnityPeriod.label"),
        isVisible: true,
        isSortedDescending: false,
        isSorted: false,
        minWidth: 100,
        maxWidth: 100,
        onRender: (item?: any, index?: number, column?: IColumn) => {
          return (
            <div>{formatNumber(item.totalPdBiBasedOnIndemnityPeriod)}</div>
          );
        },
      },
      {
        key: "statusCode",
        name: t("rvm.statusCode.label"),
        fieldName: "statusCode",
        label: t("rvm.statusCode.label"),
        isVisible: true,
        isSortedDescending: false,
        isSorted: false,
        minWidth: 120,
        maxWidth: 120,
        onRender: (item?: any, index?: number, column?: IColumn) => {
          return (
            <div>
              {t(
                taxonomy.PropertyInformationStatus.byId[item?.statusCode]?.code
              )}
            </div>
          );
        },
      },
      {
        key: "document",
        name: t("rvm.document.label"),
        fieldName: "document",
        label: t("rvm.document.label"),
        isVisible: true,
        isSortedDescending: false,
        isSorted: false,
        minWidth: 45,
        maxWidth: 45,
        onRender: (item?: any, index?: number, column?: IColumn) => {
          return item.documentPath ? (
            <>
              <Icon
                css={css`
                  cursor: pointer;
                  font-size: 20px;
                `}
                iconName="DownloadDocument"
                title={
                  item.documentPath.split("/").pop().split("#")[0].split("?")[0]
                }
                onClick={() => window.open(item.documentPath)}
              />
              &nbsp;
            </>
          ) : null;
        },
      },
      {
        key: "comments",
        name: t("rvm.comments.label"),
        fieldName: "document",
        label: t("rvm.comments.label"),
        isVisible: true,
        isSortedDescending: false,
        isSorted: false,
        minWidth: 75,
        maxWidth: 75,
        onRender: (item?: any, index?: number, column?: IColumn) => {
          const hasComments =
            !!item?.propertyInformationCommentHistories.length;
          if (!hasComments) return;

          return <HistoryComments item={item} />;
        },
      },
    ];

    setColumns(items);
  }, [taxonomy]);

  return history && history.length > 0 ? (
    <List
      checkboxVisibility={CheckboxVisibility.hidden}
      listClassName="crmList"
      scrollablePaneClassName="crmScrollablePane"
      listItems={history}
      onColumnClick={() => {}}
      listStyles={{}}
      scrollablePaneStyle={{
        root: { top: "45px" },
        stickyBelowItems: { display: "none" },
      }}
      selection={selection}
      setSelection={(selection: IObjectWithKey[]) => {
        setSelection(selection);
        // if (selection.length > 0) {
        //   historyUse.push(
        //     routes.valueMonitoringForLocation.getPath(
        //       (selection[0] as any).clientId,
        //       (selection[0] as any).locationId,
        //       (selection[0] as any).propertyInformationId
        //     )
        //   );
        // }
        if (selection.length > 0) {
          dispatch(setFormObject(selection[0]));
        }
      }}
      columns={columns}
      setColumns={setColumns}
      selectionType={SelectionMode.single}
      onItemInvoked={() => {}}
      alternateRowColors={{
        oddColor: theme.palette.white,
        evenColor: theme.palette.themeLighterAlt,
      }}
      shouldShowStickyHeader={true}
      rowStyles={{
        root: {
          color: theme.palette.neutralPrimary,
          fontWeight: 500,
          fontSize: "1.175em",
        },
      }}
      isLoading={dataLoadStatus === "loading"}
      shimmerLineLength={10}
      removeFadingOverlay={false}
      isSortClickable={false}
      isRightClickContextMenuDisabled={true}
      emptyListMessage={t("rvm.NoRecords")}
    />
  ) : (
    <>{t("rvm.nohistory.label")}</>
  );
};

export default HistoryTable;
