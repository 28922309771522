import axios from "axios";
import { EConfigurationSetting, IConfigurationSettings } from "../types/types";

const url = process.env.REACT_APP_BASE_URL;

export const getConfigurationListSettings = async ({
  clientId,
  settingsNames,
}: {
  clientId: number;
  settingsNames: string;
}) => {
  return await axios.get(
    `${url}/configurationsetting/${clientId}/${settingsNames}`
  );
};
export const getConfigurationSettings = async ({
  clientId,
  settingName,
}: {
  clientId: number | string;
  settingName: EConfigurationSetting;
}) => {
  const response = await axios.get(
    `${url}/configurationsetting/single/${clientId}/${settingName}`
  );
  return response.data;
};

export const setConfigurationSettings = async ({
  clientId,
  data,
}: {
  clientId: number | string;
  data: IConfigurationSettings;
}) => {
  const response = await axios.post(
    `${url}/configurationsetting/${clientId}`,
    data
  );
  return response.data;
};

export const updateConfigurationSettings = async ({
  clientId,
  data,
  configurationSettingId,
}: {
  clientId: number | string;
  configurationSettingId: number;
  data: IConfigurationSettings;
}) => {
  const response = await axios.patch(
    `${url}/configurationsetting/${clientId}/${configurationSettingId}`,
    data
  );
  return response.data;
};

export const deleteConfigurationSettings = async ({
  clientId,
  configurationSettingId,
  rowVersion,
}: {
  clientId: number | string;
  configurationSettingId: number;
  rowVersion: string;
}) => {
  const response = await axios.delete(
    `${url}/configurationsetting/${clientId}/${configurationSettingId}`,
    { data: { rowVersion } }
  );
  return response.data;
};
