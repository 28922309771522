import { css as cssClass } from "@emotion/css";
import { css } from "@emotion/react";
import { Formik } from "formik";
import { Dialog, DialogFooter, DialogType } from "office-ui-fabric-react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { FormSpinner } from "../../../components/FormSpinner";
import DialogButton from "../../../components/controls/DialogButton";
import { Field } from "../../../components/form/Field";
import { useSelector } from "../../../store/hooks";
import {
  approvePropertyInfoActionsRoutine,
  loadPropertyInfoForClientRoutine,
} from "../../../store/valueMonitoring/valueMonitoringPage";
import { setYupLocale } from "../../../utils/setYupLocale";

type Props = {
  close: () => void;
};

const ApproveFormDialog = ({ close }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const isLoading =
    useSelector((s) => s.valueMonitoring.dataLoadStatus) === "loading";
  const selectedLocations = useSelector((s) => s.locations.selection);
  const openedLocation = useSelector((s) => s.valueMonitoring.formObject);

  const formObjects = useMemo(() => {
    return selectedLocations && selectedLocations.length > 0
      ? selectedLocations
      : [openedLocation];
  }, [openedLocation, selectedLocations]);

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({ comment: yup.string().max(512).nullable() });
  }, [t]);

  const handleRequest = useCallback(() => {
    dispatch(
      loadPropertyInfoForClientRoutine.trigger({
        clientId: formObjects[0].clientId,
      })
    );
  }, [dispatch, formObjects]);

  const onSubmit = async (data) => {
    formObjects.forEach(async (item) => {
      await dispatch(
        approvePropertyInfoActionsRoutine.trigger({
          clientId: item.clientId,
          locationId: item.locationId,
          propertyInfoId: item.propertyInformationId,
          rowVersion: item.rowVersion,
          comment: data.comment,
          onSuccess: () => {
            handleRequest();
          },
          onError: () => {
            handleRequest();
          },
        })
      );
    });

    close();
  };

  const title = (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      {t("rvm.approve.label")}
    </div>
  );

  return (
    <Dialog
      hidden={false}
      onDismiss={close}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        className: cssClass`
        .ms-Dialog-title {
          margin: 20px;
          padding-bottom: 10px;
        }
        .ms-Dialog-inner {
          margin: 20px;
          padding-bottom: 14px;
        }
        .ms-Button {
          padding: 10px 35px;
          //background: rgb(0, 184, 238);
        }
      `,
        showCloseButton: true,
      }}
      minWidth={400}
      modalProps={{
        isBlocking: true, //phraseActivated ? false :
      }}
    >
      <Formik
        initialValues={{ comment: "" }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange={didTrySubmit}
        validateOnBlur={didTrySubmit}
      >
        {({ submitForm }) => {
          return (
            <>
              <div css={{ marginBottom: "10px" }}>
                {t("rvm.approveMessage.label")}
              </div>
              <Field
                name="comment"
                type="text"
                label={t("rvm.clientComment.label")}
                isMultiline
                rows={2}
                isRequired={false}
              />
              <DialogFooter>
                <DialogButton
                  // iconName={"DocumentApproval"}
                  text={t("rvm.confirm.label")}
                  disabled={isLoading}
                  operationFn={() => {
                    setDidTrySubmit(true);
                    submitForm();
                  }}
                />
              </DialogFooter>
              {isLoading && <FormSpinner />}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ApproveFormDialog;
