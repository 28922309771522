export const handleScrollToElement = (
  classNamePrefix: string,
  ev?: any,
  item?: any,
  behavior: any = "smooth"
) => {
  const tab: any = document.querySelector(`${classNamePrefix}${item!.key!}`);

  tab &&
    document.querySelector(".app-wrapper")!.scroll({
      top: tab.offsetTop,
      left: 0,
      behavior,
    });
};

export const calculateListHeight = (
  rowHeight: number,
  headerHeight: number,
  itemCount: number,
  maximumNumberOfItemsToBeShownInList: number,
  maxListHeight: number
): number => {
  return itemCount > maximumNumberOfItemsToBeShownInList
    ? maxListHeight
    : itemCount * rowHeight + headerHeight;
};
