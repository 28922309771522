import React from "react";

interface iconProps {
  height?: string;
  width?: string;
  className?: string;
}

export const GeneralIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : "29"}
      height={props.height ? props.height : "29"}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29Z"
        fill="#009A9B"
      />
      <path
        d="M20.1366 13.0324C20.1366 12.1547 19.4333 11.4434 18.5654 11.4434C17.6975 11.4434 16.9942 12.1547 16.9942 13.0324C16.9942 13.678 17.3754 14.2332 17.9223 14.4818V15.8731L14.8231 19.0072V7.3343C15.37 7.08561 15.7512 6.53048 15.7512 5.88482C15.7512 5.00711 15.0479 4.2959 14.18 4.2959C13.3126 4.2959 12.6088 5.00711 12.6088 5.88482C12.6088 6.53048 12.99 7.08561 13.5369 7.3343V9.03873L9.22402 13.4002V15.5104C8.67713 15.7591 8.2959 16.3148 8.2959 16.9604C8.2959 17.8381 8.99919 18.5494 9.86711 18.5494C10.735 18.5494 11.4383 17.8381 11.4383 16.9604C11.4383 16.3148 11.0571 15.7596 10.5102 15.5109V13.9392L13.5369 10.8784V23.0206C13.0497 23.2906 12.7194 23.8135 12.7194 24.4149C12.7194 25.2926 13.4227 26.0039 14.2906 26.0039C15.1585 26.0039 15.8618 25.2926 15.8618 24.4149C15.8618 23.7266 15.4291 23.1408 14.8236 22.9202V20.8474L19.209 16.4126V14.4824C19.7554 14.2337 20.1366 13.678 20.1366 13.0324Z"
        fill="#DBFFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2091 16.4127L14.8237 20.8475V22.9203C15.4292 23.1409 15.8619 23.7267 15.8619 24.4151C15.8619 25.2928 15.1586 26.004 14.2907 26.004C13.4228 26.004 12.7195 25.2928 12.7195 24.4151C12.7195 23.8136 13.0498 23.2907 13.537 23.0207V10.8785L10.5103 13.9393V15.5111C11.0572 15.7598 11.4384 16.3149 11.4384 16.9606C11.4384 17.8383 10.7352 18.5495 9.86723 18.5495C8.99931 18.5495 8.29602 17.8383 8.29602 16.9606C8.29602 16.3149 8.67725 15.7592 9.22414 15.5105V13.4003L13.537 9.03884V7.33442C12.9901 7.08573 12.6089 6.5306 12.6089 5.88494C12.6089 5.00723 13.3127 4.29602 14.1801 4.29602C15.048 4.29602 15.7513 5.00723 15.7513 5.88494C15.7513 6.5306 15.3701 7.08573 14.8232 7.33442V19.0073L17.9224 15.8732V14.482C17.3755 14.2333 16.9943 13.6781 16.9943 13.0325C16.9943 12.1548 17.6976 11.4436 18.5655 11.4436C19.4334 11.4436 20.1367 12.1548 20.1367 13.0325C20.1367 13.6781 19.7555 14.2338 19.2091 14.4825V16.4127ZM19.5051 14.6617C20.06 14.3346 20.4327 13.727 20.4327 13.0325C20.4327 11.9944 19.6 11.1475 18.5655 11.1475C17.531 11.1475 16.6983 11.9944 16.6983 13.0325C16.6983 13.7272 17.0711 14.3343 17.6264 14.6612V15.7515L15.1192 18.287V7.51369C15.6745 7.18679 16.0473 6.57964 16.0473 5.88494C16.0473 4.84688 15.2146 4 14.1801 4C13.1461 4 12.3129 4.84682 12.3129 5.88494C12.3129 6.57964 12.6857 7.18679 13.241 7.51369V8.9172L8.92812 13.2787V15.3313C8.37277 15.6583 8 16.2659 8 16.9606C8 17.9986 8.8327 18.8455 9.86723 18.8455C10.9018 18.8455 11.7345 17.9986 11.7345 16.9606C11.7345 16.2659 11.3616 15.6587 10.8063 15.3318V14.061L13.241 11.5989V22.8562C12.7473 23.1956 12.4235 23.7673 12.4235 24.4151C12.4235 25.4531 13.2562 26.3 14.2907 26.3C15.3252 26.3 16.1579 25.4531 16.1579 24.4151C16.1579 23.6756 15.736 23.0345 15.1197 22.726V20.9692L19.5051 16.5343V14.6617Z"
        fill="#DBFFFF"
      />
    </svg>
  );
};
