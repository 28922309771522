import { PrimaryButton } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";

const saveBtnStyle = {
  root: {
    height: "100%",
  },
};

const DialogButton = ({ disabled, operationFn, text, iconName }: any) => {
  const { t } = useTranslation();

  return (
    <PrimaryButton
      styles={saveBtnStyle}
      type="button"
      text={text}
      disabled={disabled}
      onClick={() => {
        operationFn();
      }}
    />
  );
};

export default DialogButton;
