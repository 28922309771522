import { AccountInfo } from "@azure/msal-browser";
import { GrecoSpinner } from "@greco/services";
import { initializeIcons } from "@uifabric/icons";
import Axios from "axios";
import { mergeStyles } from "office-ui-fabric-react";
import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts.js";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { toast } from "react-toastify";
import "react-virtualized/styles.css";
import "../node_modules/@greco/services/dist/lib/main.css";
import "../node_modules/react-toastify/dist/ReactToastify.css";
import { App } from "./App";
import { AppGos } from "./AppGos";
import { MSGraphAuth } from "./api/GraphService";
import { cacheLocation, checkIsLoggedIn, getToken, msal } from "./api/auth";
import { RvmBFFParams } from "./config/MSGraphConfig";
import "./i18n";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store/store";
import { deleteAllCookies, getCookieValue } from "./utils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const isDev = process.env.NODE_ENV === "development";
const isGOS = process.env.REACT_APP_GOS === "true";

if (document.cookie.length > 5000) {
  deleteAllCookies();
}

const APP_URL = isDev ? "http://localhost:3000" : process.env.REACT_APP_APP_URL;
const appId = process.env.REACT_APP_CLIENT_ID!;

pdfMake.fonts = {
  SegoeUI: {
    normal: `${APP_URL}/fonts/SEGOEUI.ttf`,
    bold: `${APP_URL}/fonts/SEGOEUI.ttf`,
  },
  Remix: {
    normal: `${APP_URL}/fonts/remixicon.ttf`,
  },
  LineAwesome: {
    normal: `${APP_URL}/fonts/la-regular-400.ttf`,
  },
};

toast.configure();

mergeStyles({
  selectors: {
    ":global(body), :global(html), :global(#root)": {
      margin: 0,
      padding: 0,
      height: "100vh",
    },
  },
});

Axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

Axios.interceptors.request.use(async (config) => {
  if (isGOS) {
    let authResponse = await MSGraphAuth.getAccessToken(RvmBFFParams);

    if (!authResponse) return config;
    if (config.url?.indexOf("thumbnailPhoto") !== -1) {
      return config;
    }
    config.headers.Authorization = `Bearer ${authResponse.accessToken}`;
    return config;
  } else {
    const authRes = await getToken();
    let token;
    if (authRes) {
      token = authRes.accessToken || authRes.idToken.rawIdToken;
    }
    if (!token) {
      token = cacheLocation.getItem("msal.idtoken");
      console.log("Token from local storage", token);
    }
    if (!token) {
      token = getCookieValue("msal.idtoken");
      console.log("Token from cookie", token);
    }
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  }
});

const startApplication = () => {
  initializeIcons();
  ReactDOM.render(
    <Suspense fallback={<GrecoSpinner />}>
      <Provider store={store}>{isGOS ? <AppGos /> : <App />}</Provider>
    </Suspense>,
    document.getElementById("root")
  );
};
if (isGOS) {
  MSGraphAuth.accountObj
    .then((accountInfo: AccountInfo | null) => {
      if (accountInfo) {
        startApplication();
      } else {
        MSGraphAuth.login();
      }
    })
    .catch((error) => {
      if (error === "There isn't any account detected.") {
        MSGraphAuth.login();
      } else {
        console.log(error);
      }
    });

  serviceWorker.unregister();
} else {
  const fn = async () => {
    const isLoggedIn = await checkIsLoggedIn();
    if (isLoggedIn) {
      startApplication();
    } else {
      msal.loginRedirect({
        scopes: [appId],
      });
    }
  };
  fn();
}
