import axios, { CancelToken } from "axios";
import {
  Client,
  ClientLocation,
  ClientLocationWithUsers,
  ExcelReportResponse,
  LocationDefaultValue,
  RamUser,
  RiskInsurerUser,
  RiskUser,
  Setting,
  SettingsResponse,
  TaxonomyResponse,
  TaxonomyUADResponse,
} from "../types/types";
import { MSGraphAuth } from "./GraphService";

const url = process.env.REACT_APP_BASE_URL;

const uadUrl = process.env.REACT_APP_BFF_HOST_UAD;

export const getPropertyInfos = async () => {
  const res = await axios.get<any[]>(`${url}/propertyinfos/0`);
  return res;
};

export const getPropertyInfosForClient = async ({
  clientId,
}: {
  clientId: any;
}) => {
  const res = await axios.get<any[]>(
    `${url}/propertyinfos/client/${clientId}/0`
  );
  return res;
};

export const getPropertyInfosForLocation = async (
  clientId: number | undefined,
  locationId: number | undefined,
  statusId: number | undefined
) => {
  const res = await axios.get<any[]>(
    `${url}/propertyinfos/location/${clientId}/${locationId}/${statusId}`
  );
  return res;
};

export const getClientsList = async () => {
  const res = await axios.get<Client[]>(`${url}/propertyinfos/0`);
  return res;
};

export type ExcelReportRequestParams = {
  clientId: string;
};

export const getPropertyInfo = async ({
  clientId,
  locationId,
  propertyInformationId,
}: {
  clientId: any;
  locationId: any;
  propertyInformationId: any;
}) => {
  const res = await axios.get<any>(
    `${url}/propertyinfos/${clientId}/${locationId}/${propertyInformationId}`
  );
  return res;
};

export const getLastPropertyInfo = async ({
  clientId,
  locationId,
}: {
  clientId: any;
  locationId: any;
}) => {
  const res = await axios.get<any>(
    `${url}/propertyinfos/last/${clientId}/${locationId}`
  );
  return res;
};

export const updatePropertyInfo = async ({
  clientId,
  locationId,
  propertyInfoId,
  formObject,
}: {
  clientId: any;
  locationId: any;
  propertyInfoId: any;
  formObject: any;
}) => {
  const res = await axios.patch<any>(
    `${url}/propertyinfos/${clientId}/${locationId}/${propertyInfoId}`,
    { ...formObject }
  );
  return res;
};

export const approveLocation = async ({
  clientId,
  locationId,
}: {
  clientId: any;
  locationId: any;
}) => {
  const res = await axios.patch<any>(
    `${url}/propertyinfos/location/approve/${clientId}/${locationId}`,
    {}
  );
  return res;
};

export const deleteLocation = async ({
  clientId,
  locationId,
  comment,
  rowVersion,
}: {
  clientId: any;
  locationId: any;
  comment: any;
  rowVersion: any;
}) => {
  const res = await axios.delete<any>(
    `${url}/propertyinfos/${clientId}/${locationId}`,
    { data: { grECoComment: comment, rowVersion: rowVersion } }
  );
  return res;
};

export const deletePropertyInfo = async ({
  clientId,
  locationId,
  propertyInfoId,
  comment,
  rowVersion,
}: {
  clientId: any;
  locationId: any;
  propertyInfoId: any;
  comment: any;
  rowVersion: any;
}) => {
  const res = await axios.delete<any>(
    `${url}/propertyinfos/${clientId}/${locationId}/${propertyInfoId}`,
    { data: { grECoComment: comment, rowVersion: rowVersion } }
  );
  return res;
};

export const addPropertyInfo = async ({ formObject }: { formObject: any }) => {
  const res = await axios.post<any>(`${url}/propertyinfos`, [...formObject]);
  return res;
};

export const addLocation = async ({
  clientId,
  formObject,
}: {
  clientId: any;
  formObject: any;
}) => {
  const res = await axios.post<any>(
    `${url}/propertyinfos/location/${clientId}`,
    { ...formObject }
  );
  return res;
};

export const sendReminder = async ({ formObject }: { formObject: any }) => {
  const res = await axios.post<any>(`${url}/propertyinfos/reminders`, [
    ...formObject,
  ]);
  return res;
};

export const addSinglePropertyInfo = async ({
  clientId,
  locationId,
  formObject,
}: {
  clientId: any;
  locationId: any;
  formObject: any;
}) => {
  const res = await axios.post<any>(
    `${url}/propertyinfos/${clientId}/${locationId}`,
    { ...formObject[0] }
  );
  return res;
};

export const approvePropertyInfo = async ({
  clientId,
  locationId,
  propertyInfoId,
  rowVersion,
  comment,
}: {
  clientId: any;
  locationId: any;
  propertyInfoId: any;
  rowVersion: any;
  comment: any;
}) => {
  const res = await axios.patch<any>(
    `${url}/propertyinfos/approve/${clientId}/${locationId}/${propertyInfoId}`,
    { GrECoComment: comment, rowVersion }
  );
  return res;
};

export const rejectPropertyInfo = async ({
  clientId,
  locationId,
  propertyInfoId,
  rowVersion,
  comment,
}: {
  clientId: any;
  locationId: any;
  propertyInfoId: any;
  rowVersion: any;
  comment: any;
}) => {
  const res = await axios.patch<any>(
    `${url}/propertyinfos/reject/${clientId}/${locationId}/${propertyInfoId}`,
    { GrECoComment: comment, rowVersion }
  );
  return res;
};

export const completePropertInfo = async ({
  clientId,
  locationId,
  propertyInfoId,
  completed,
  formObject,
}: {
  clientId: any;
  locationId: any;
  propertyInfoId: any;
  completed: any;
  formObject: any;
}) => {
  const res = await axios.patch<any>(
    `${url}/propertyinfos/${clientId}/${locationId}/${propertyInfoId}/${completed}`,
    { ...formObject }
  );
  return res;
};

export const setRatingLimits = async (
  clientId: number | undefined,
  body: any
) => {
  const res = await axios.patch<Client>(`${url}/clients/${clientId}`, body);
  return res;
};

export const setRatingLimitsHierarchically = async (
  clientId: number | undefined,
  body: any
) => {
  const res = await axios.patch<Client>(
    `${url}/clients/hierarchically/${clientId}`,
    body
  );
  return res;
};

export const searchClients = async (firstLetters: string) => {
  const res = await axios.get<any[]>(`${url}/clientssearch/${firstLetters}`);
  return res;
};

export const getTaxonomyData = async () => {
  const res = await axios.get<TaxonomyResponse>(`${url}/taxonomy`);
  return res;
};

export const getClientInfo = async (clientId: string) => {
  const res = await axios.get<any>(`${url}/client/${clientId}`);
  return res;
};

export const getLocations = async () => {
  const res = await axios.get<ClientLocation[]>(`${url}/clientlocations`);
  return res;
};

export const createLocationWithUsers = async (data: any) => {
  const res = await axios.post<ClientLocationWithUsers>(
    `${url}/clientlocationswithusers`,
    data
  );
  return res;
};

export const exportToZip = async (locationId: number) => {
  const res = await axios.post<ClientLocationWithUsers>(
    `${url}/clientlocations/exportattachments/${locationId}`,
    locationId
  );
  return res;
};

export const generateLocationReportDocument = async (
  locationId: number,
  body: any
) => {
  const res = await axios.post(
    `${url}/clientlocations/reportdocument/${locationId}`,
    body
  );
  return res;
};

export const generateClientReportDocument = async (
  args: ExcelReportRequestParams
) => {
  const res = await axios.get(
    `${url}/propertyinfos/location/report/${args.clientId}`
  );
  return res;
};

export const generateExternalReportDocument = async () => {
  const res = await axios.get(`${url}/propertyinfos/location/report`);
  return res;
};

export const generateExcelReport = async (args: ExcelReportRequestParams) => {
  const { clientId } = args;
  return await axios.post<ExcelReportResponse>(
    `${url}/GenerateFleetVehicleReport/${clientId}`
  );
};

export const exportPowerBIReport = async (data: any) => {
  const res = await axios.post<ClientLocationWithUsers>(
    `${url}/powerbi/export`,
    data
  );
  return res;
};

export const searchUsers = async (firstLetters: string) => {
  const res = await axios.get<RamUser[]>(`${url}/userssearch/${firstLetters}`);
  return res.data;
};

export const getReports = async () => {
  const res = await axios.get(`${url}/reports`);
  return res;
};

// export const deleteLocation = async (locationId: number | undefined) => {
//   const res = await axios.delete<any>(`${url}/clientlocations/${locationId}`);
//   return res.data;
// };

export const getLocation = async (locationId: number | undefined) => {
  return await axios.get<ClientLocationWithUsers>(
    `${url}/clientlocationswithusers/${locationId}`
  );
};

export const updateLocationWithUsers = async (
  locationId: number | undefined,
  data: any
) => {
  const res = await axios.patch<ClientLocationWithUsers>(
    `${url}/clientlocationswithusers/${locationId}`,
    data
  );
  return res;
};

export const updateReportWithInsurerUsers = async (
  locationId: number | undefined,
  reportId: number | undefined,
  data: any
) => {
  const res = await axios.patch<any>(
    `${url}/reportswithinsurerusers/${locationId}/${reportId}`,
    data
  );
  return res;
};

export const getUsersByLocation = async (locationId: number | undefined) => {
  const res = await axios.get<any>(`${url}/users/${locationId}`);
  return res.data;
};

export const getLocationSummary = async (
  locId: number,
  categories: number[]
) => {
  let lsids = locId + "@" + categories.join(",");

  const res = await axios.get(`${url}/clientlocations/summary/${lsids}`);
  return res;
};

export const getDefaultValues = async (locId: number) => {
  const res = await axios.get<LocationDefaultValue[]>(
    `${url}/clientlocationassessmentsubcategorysetups/${locId}`
  );
  return res;
};

export const updateDefaultValues = async (
  locId: number,
  defaultValues: LocationDefaultValue[]
) => {
  const res = await axios.patch(
    `${url}/clientlocationassessmentsubcategorysetups/${locId}`,
    defaultValues
  );
  return res;
};

export const startAssessment = async (locationId: number) => {
  const res = await axios.patch(
    `${url}/clientlocations/startassessment/${locationId}`
  );
  return res;
};

export const endAssessment = async (locationId: number) => {
  const res = await axios.patch(
    `${url}/clientlocations/endassessment/${locationId}`
  );
  return res;
};

export const getAllUsersForClient = async (clientId: number) => {
  const res = await axios.get<RiskUser[]>(`${url}/users/client/${clientId}`);
  return res;
};

export const getUsersList = async () => {
  const res = await axios.get<RiskUser[]>(`${url}/users`);
  return res;
};

export const getUsersListInsurer = async () => {
  const res = await axios.get<RiskInsurerUser[]>(`${url}/insurerusers`);
  return res;
};

export const getLocationsByUserId = async (
  userId: string,
  userType: number,
  cancelToken: CancelToken
) => {
  const res = await axios.get(
    `${url}/clientlocations/user/${userId}?userType=${userType}`,
    {
      cancelToken,
    }
  );
  return res;
};

export const getReportsByUserId = async (
  userId: string,
  cancelToken: CancelToken
) => {
  const res = await axios.get(`${url}/reports/insureruser/${userId}`, {
    cancelToken,
  });
  return res;
};

export const updateLocationsByUserId = async (userId: string, data: any) => {
  const res = await axios.patch(`${url}/clientlocations/user/${userId}`, data);
  return res;
};

export const updateReportsByUserId = async (userId: string, data: any) => {
  const res = await axios.patch(`${url}/reports/insureruser/${userId}`, data);
  return res;
};

export const getAppSettings = async (appID: number) => {
  return await axios.get<SettingsResponse>(`${uadUrl}/appsettings/${appID}`);
};

export const getUADTaxonomy = async () => {
  return await axios.get<TaxonomyUADResponse>(`${uadUrl}/taxonomy`);
};

export const addAppSetting = async (setting: Partial<Setting>) => {
  return await axios.post<Setting[]>(`${uadUrl}/addappsettings`, [setting]);
};

export const deleteAppSetting = async (
  userAppSettingId: Setting["userAppSettingId"]
) => {
  return await axios.delete<Setting[]>(`${uadUrl}/deleteappsettings`, {
    data: [userAppSettingId],
  });
};

export const updateAppSetting = async (setting: Setting) => {
  return await axios.patch<Setting[]>(`${uadUrl}/updateappsettings`, [setting]);
};

export const getUserRoles = async (): Promise<string[]> => {
  const account = await MSGraphAuth.accountObj;
  if (!(account && account.idTokenClaims)) return [];

  const { roles } = account.idTokenClaims as { roles: string[] };
  return roles;
};

export type SetRatingLimitsParams = {
  clientId: number;
  updateHierarchically: boolean;
  body: any;
};

export type GenerateLocationReportDocumentParams = {
  locationId: number;
  body: any;
};

export type ExportToZipParams = {
  locationId: any;
};
