import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../store/hooks";

function useFormFieldsOptions() {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);

  const currencyCodeOptions = useMemo(() => {
    return taxonomy.Currency.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy]);

  const locationOwningStatusCodeOptions = useMemo(() => {
    return taxonomy.LocationOwnershipStatus.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy]);

  const locationStatusCodeOptions = useMemo(() => {
    return taxonomy.LocationStatus.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy]);

  const indemnityCodeOptions = useMemo(() => {
    return taxonomy.IndemnityPeriod.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy]);

  return {
    currencyCodeOptions,
    locationOwningStatusCodeOptions,
    locationStatusCodeOptions,
    indemnityCodeOptions,
  };
}

export default useFormFieldsOptions;
