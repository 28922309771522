export const accept = /[-\d]+/g;

export const parse = (string: string) => {
  return (String(string).match(accept) || []).join("");
};

export const format = ({
  value,
  isNegativeAllowed,
  max,
  includeThousandSeparators = true,
}: {
  value;
  isNegativeAllowed?: boolean;
  max?: number;
  includeThousandSeparators?: boolean;
}) => {
  let parsed = parse(value);
  if (parsed === "-") {
    if (!isNegativeAllowed) {
      return "";
    }
    return "-";
  }
  //
  else if (parsed.indexOf("-") !== -1) {
    if (!isNegativeAllowed) {
      parsed = "0";
    }
  }

  let number = Number.parseFloat(parsed);

  if (Number.isNaN(number)) {
    return "";
  }

  if (max !== undefined && number > max) {
    number = max;
  }

  if (includeThousandSeparators) {
    const formatted = number.toLocaleString("de-DE", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatted.slice(0, 16);
  }
  //
  else {
    return number.toString().slice(0, 16);
  }
};
