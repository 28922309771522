import axios from "axios";
import { ICampaignInfo } from "../types/types";

const url = process.env.REACT_APP_BASE_URL;

// const uadUrl = process.env.REACT_APP_BFF_HOST_UAD;

export const getLastClientCampaignInfo = async ({
  clientId,
}: {
  clientId: number | string;
}): Promise<ICampaignInfo> => {
  const res = await axios.get(`${url}/client/campaign/last/${clientId}`);

  return res.data;
};
export const getClientCampaignHistoryInfo = async ({
  clientId,
}: {
  clientId: number | string;
}): Promise<ICampaignInfo> => {
  const res = await axios.get(`${url}/client/campaign/history/${clientId}`);

  return res.data;
};

export const setClientCampaignStatus = async ({
  clientId,
  shouldOpen,
  data,
}: {
  clientId: number | string;
  shouldOpen: boolean;
  data: {
    rowVersion: string;
    comment: string;
  };
}) => {
  const res = await axios.patch(
    `${url}/client/campaign/${clientId}/${shouldOpen}`,
    data
  );
  return res.data;
};
