import React, { useRef, useState } from 'react';
import {
  ContextualMenu,
  DirectionalHint,
  FontIcon,
  getTheme,
  IContextualMenuItem,
  mergeStyles,
  mergeStyleSets,
  TooltipHost,
  TooltipOverflowMode
} from 'office-ui-fabric-react';

interface IProps {
  text: any;
  applyTextOverflow?: boolean;
  tooltipDirection?: DirectionalHint;
  textWithIconClassName?: string;
  iconClassName?: string;
  iconNameClassName?: string;
  textClassName?: string;
  textWithIconStyle?: any;
  iconStyle?: any;
  iconNameStyle?: any;
  textStyle?: any;
  iconTitle?: string;
  iconName?: string;
  enableTextHover?: boolean;
  onRenderIcon?: () => JSX.Element | null;
  onTextClick?: () => void;
  // if needed in future we can include whole contextual menu object props
  contextualMenuItems?: IContextualMenuItem[];
  leftClickContextualMenuItems?: IContextualMenuItem[];
}

export const BasicTextWithIcon = (props: IProps): JSX.Element => {
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [showLeftClickContextMenu, setShowLeftClickContextMenu] =
    useState<boolean>(false);
  const linkRef = useRef(null);
  const theme = getTheme();

  const generalStyles = mergeStyleSets({
    textClassName: {
      wordBreak: 'break-word',
      color: theme.palette.black
    }
  });

  return (
    <>
      <span style={{ display: 'flex' }}>
        <span
          className={props.textWithIconClassName}
          // if applyTextOverflow is active modify textWithIconStyle
          style={
            !props.applyTextOverflow
              ? props.textWithIconStyle
              : {
                  overflow: 'hidden',
                  ...props.textWithIconStyle
                }
          }
          onClick={
            props.leftClickContextualMenuItems?.length
              ? () => setShowLeftClickContextMenu(true)
              : props.onTextClick
          }
          ref={linkRef}
          onContextMenu={(e) => {
            if (
              props.contextualMenuItems &&
              props.contextualMenuItems.length !== 0
            ) {
              setShowContextMenu(true);
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          {props.iconName || props.onRenderIcon ? (
            <div className={props.iconClassName} style={props.iconStyle}>
              {props.onRenderIcon ? (
                props.onRenderIcon()
              ) : props.iconName ? (
                <FontIcon
                  iconName={props.iconName}
                  title={props.iconTitle}
                  style={props.iconNameStyle}
                  className={props.iconNameClassName}
                />
              ) : null}
            </div>
          ) : null}

          {props.text ? (
            <span
              className={`${generalStyles.textClassName} ${
                props.textClassName ? props.textClassName : ''
              } ${
                props.enableTextHover
                  ? mergeStyles({
                      '&:hover': {
                        textDecoration: 'underline',
                        cursor: 'pointer'
                      }
                    })
                  : ''
              }`}
              // if applyTextOverflow is active modify textStyle
              style={
                !props.applyTextOverflow
                  ? props.textStyle
                  : {
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      ...props.textStyle
                    }
              }
            >
              {!props.applyTextOverflow ? (
                // only text
                props.text
              ) : (
                // applyTextOverflow
                <TooltipHost
                  overflowMode={TooltipOverflowMode.Parent}
                  content={props.text}
                  directionalHint={props.tooltipDirection}
                >
                  {props.text}
                </TooltipHost>
              )}
            </span>
          ) : null}
        </span>
      </span>
      {props.contextualMenuItems && props.contextualMenuItems.length !== 0 ? (
        <ContextualMenu
          items={props.contextualMenuItems}
          hidden={!showContextMenu}
          target={linkRef}
          onDismiss={() => setShowContextMenu(false)}
          onItemClick={() => setShowContextMenu(false)}
          directionalHint={DirectionalHint.bottomLeftEdge}
        />
      ) : null}
      {props.leftClickContextualMenuItems &&
      props.leftClickContextualMenuItems.length !== 0 ? (
        <ContextualMenu
          items={props.leftClickContextualMenuItems}
          hidden={!showLeftClickContextMenu}
          target={linkRef}
          onDismiss={() => setShowLeftClickContextMenu(false)}
          onItemClick={() => setShowLeftClickContextMenu(false)}
          directionalHint={DirectionalHint.bottomLeftEdge}
        />
      ) : null}
    </>
  );
};
