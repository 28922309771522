import { useFormikContext } from "formik";
import { useEffect } from "react";
import { stringToNumber } from "../../../utils/utils";

export const useTotalPdBiField = () => {
  const formik = useFormikContext();
  const totalPd =
    formik.values["totalPd"] && formik.values["totalPd"] !== ""
      ? stringToNumber(formik.values["totalPd"])
      : 0;
  const businessInteruptionValues12Months =
    formik.values["businessInteruptionValues12Months"] &&
    formik.values["businessInteruptionValues12Months"] !== ""
      ? stringToNumber(formik.values["businessInteruptionValues12Months"])
      : 0;

  useEffect(() => {
    const totalPdBiValue = totalPd + businessInteruptionValues12Months;
    const totalPdBiString = totalPdBiValue.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (formik.dirty && !formik.isSubmitting) {
      formik.setFieldValue("totalPdBi", totalPdBiString);
    }
  }, [
    totalPd,
    formik.touched["totalPd"],
    formik.values["totalPd"],
    businessInteruptionValues12Months,
    formik.touched["businessInteruptionValues12Months"],
    formik.values["businessInteruptionValues12Months"],
  ]);
};
