export const getPinIcon = (
  width: number,
  height: number,
  fillColor: string
) => {
  return `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"  width="${width}"  height="${height}"
  viewBox="0 0 355 512" style="enable-background:new 0 0 355 512;" xml:space="preserve" fill="${fillColor}">
<g>
 <path d="M182,12.5c-93.1,0-168.8,75.7-168.8,168.8c0,38.1,26.7,100.8,79.4,186.5c38.5,62.7,77.6,115.5,78,116.1l11.5,15.5
   l11.5-15.5c0.4-0.5,39.5-53.4,78-116.1c52.7-85.7,79.4-148.5,79.4-186.5C350.8,88.3,275.1,12.5,182,12.5z M182.1,267.17
   c-51.85,0-93.88-42.03-93.88-93.88c0-51.85,42.03-93.88,93.88-93.88c51.85,0,93.88,42.03,93.88,93.88
   C275.98,225.14,233.95,267.17,182.1,267.17z"/>
</g>
</svg>`;
};
