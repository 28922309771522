import { CommandBarButton } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { routes } from "../../config/routes";
import { useSelector } from "../../store/hooks";

type Props = {
  text?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  path?: string;
};

const backBtnStyle = {
  root: {
    height: "100%",
  },
};

export const BackButton = ({ text, onClick, isDisabled, path }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const clientId = useSelector((s) => s.clients.clientId);

  const goTo = () => {
    history.push(routes.locations.getPath(clientId));
  };
  const buttonText = t(text || "rvm.backToLocations.label");
  return (
    <CommandBarButton
      iconProps={{ iconName: "Back" }}
      onClick={goTo}
      styles={backBtnStyle}
      text={buttonText}
      disabled={isDisabled}
    />
  );
};
