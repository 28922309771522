import { useCallback, useState } from "react";
import * as yup from "yup";

const defaultSchemaObj = {
  currencyCode: yup.mixed().required(),
  buildingValues: yup.string().required(),
  equipmentValues: yup.string().required(),
  stockValues: yup.string().required(),
  totalPd: yup.string().required(),
  totalPdBi: yup.string().required(),
  indemnityPeriodCode: yup.mixed().required(),
  businessInteruptionValuesBasedOnIndemnityPeriod: yup.string().required(),
  totalPdBiBasedOnIndemnityPeriod: yup.string().required(),
  //clientComment: yup.string().required(),
  coveragePeriodFromDate: yup.date().required(),
  coveragePeriodToDate: yup.date().required(),
  desiredLocationOwningStatusCode: yup.mixed().required(),
  desiredLocationStatusCode: yup.mixed().required(),
  desiredLocationOwningStatusDate: yup.date().required(),
  desiredLocationStatusDate: yup.date().required(),
};

function useValidationSchema() {
  const [validationSchemaObj, setValidationSchemaObject] =
    useState(defaultSchemaObj);

  const handleUpdateSchema = useCallback((addedSchemaObj) => {
    setValidationSchemaObject((prev) => {
      return {
        ...prev,
        ...addedSchemaObj,
      };
    });
  }, []);

  const validationSchema = yup.object().shape(validationSchemaObj);
  return {
    validationSchema,
    handleUpdateSchema,
  };
}

export default useValidationSchema;
