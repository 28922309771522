import { css } from "@emotion/css";
import { GlobalStyles, ThemeProvider } from "@greco/components";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ConnectedRouter } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getUserPhotoSmall } from "./api/GraphService";
import { UserDataLoader } from "./api/UserDataLoader";
import "./App.scss";
import { AppHeader, UserHeader } from "./components/AppHeader/AppHeader";
import { sideBarWidth } from "./components/config/ui";
import { NoAccess } from "./components/NoAccess";
import { CrmSidebar } from "./components/Sidebar/CrmSidebar";
import { default as CrmSideBarContextProvider } from "./components/Sidebar/CrmSideBarContext";

import { Tooltip } from "./components/Tooltip";
import { headerConfig, IHeaderConfig } from "./config/headerConfig";
import { routes } from "./config/routes";
import { setIsLoggedIn } from "./store/auth";
import { loadBingMaps } from "./store/bingMaps";
import { useSelector } from "./store/hooks";
import { AppDispatch, history } from "./store/store";
import { loadTaxonomyActions } from "./store/taxonomy/taxonomy";
import { setIsLoadingUserRole, setUserRoleAndEmail } from "./store/userRole";

interface IAppState {
  headerConfig: IHeaderConfig;
  panelIsOpen: boolean;
  user: any;
  error: string;
  loading: boolean;
  search: string;
  appInsights: ApplicationInsights;
}

export const AppGos = () => {
  const [state, setState] = useState<IAppState>({
    headerConfig: headerConfig,
    panelIsOpen: false,
    user: {},
    error: "",
    loading: false,
    search: "",
    appInsights: null,
  });
  const [user, setUser] = useState<UserHeader | null>(null);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const hasRights = useSelector((s) => s.auth.hasRights);

  // let query = useQuery();
  // useEffect(() => {
  //   const darkMode = query.get("darkMode");
  //   if (darkMode) {
  //     localStorage.setItem("darkMode", darkMode);
  //     // setState({
  //     //   ...state,
  //     //   headerConfig: {
  //     //     ...state.headerConfig,
  //     //     darkMode: darkMode,
  //     //   },
  //     // });
  //   }
  // }, [query]);

  // on mount
  useEffect(() => {
    const fn = async () => {
      const usp = new URLSearchParams(window.location.search);

      dispatch(setIsLoggedIn({ isLoggedIn: true }));
    };
    fn();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      dispatch(loadBingMaps());
      dispatch(loadTaxonomyActions.trigger());
      dispatch(setIsLoadingUserRole(true));
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      setIsUserLoading(true);
      const userData = await UserDataLoader.getUserData();
      const imgUrl = await getUserPhotoSmall();
      setUser({ ...userData, photoSmall: imgUrl, photoMedium: imgUrl });
      dispatch(
        setUserRoleAndEmail({
          userRole: "internal",
          email: userData.userPrincipalName,
        })
      );
      setIsUserLoading(false);
    })();
  }, []);

  return (
    <ConnectedRouter history={history}>
      <ThemeProvider isDarkMode={Boolean(state.headerConfig.darkMode)}>
        <CrmSideBarContextProvider>
          {hasRights && user && (
            <>
              <AppHeader
                key={Boolean(state.headerConfig.darkMode).toString()}
                user={user}
                darkMode={(isDarkMode) => {
                  localStorage.setItem("darkMode", String(isDarkMode));
                  setState({
                    ...state,
                    headerConfig: {
                      ...state.headerConfig,
                      darkMode: isDarkMode,
                    },
                  });
                }}
              />
              <CrmSidebar />
              <div
                className={`app-wrapper`}
                css={css`
                  margin-left: ${sideBarWidth}px;
                `}
              >
                <Switch>
                  {Object.keys(routes).map((key) => {
                    const route = routes[key];
                    return (
                      <Route
                        key={key}
                        path={route.path}
                        component={route.component}
                        exact
                      />
                    );
                  })}
                  <Redirect to="/locations" />
                </Switch>
                <ToastContainer />
                <GlobalStyles />
                <Tooltip />
              </div>
            </>
          )}
          {!hasRights && <NoAccess />}
        </CrmSideBarContextProvider>
      </ThemeProvider>
    </ConnectedRouter>
  );
};
