import { Selection } from "@fluentui/react/lib/Utilities";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useUnmount } from "react-use";
import useQuery from "../../api/hooks/useQuery";
import {
  loadClientInfoRoutine,
  setClientId,
} from "../../store/clients/clients";
import { useSelector } from "../../store/hooks";
import {
  hideDeleteLocationDialog,
  loadLocationsForClientRoutine,
  resetState,
  setSelection,
  setShowApproveLocationDialog,
  setShowInvitationForm,
  setShowLocationInfoDialog,
  setShowReminderForm,
} from "../../store/locations/locations";

import moment from "moment";
import { CrmSideBarContext } from "../../components/Sidebar/CrmSideBarContext";
import { AppDispatch } from "../../store/store";
import ApproveFormDialog from "../valueMonitoring/dialogs/ApproveForm";
import RejectFormDialog from "../valueMonitoring/dialogs/RejectForm";
import LocationsHeader from "./LocationsHeader";
import ApproveLocationDialog from "./dialogs/ApproveLocationDialog";
import DeleteLocationDialog from "./dialogs/DeleteLocationDialog";
import { Dialog } from "./dialogs/Dialog";
import LocationInfoDialog from "./dialogs/LocationInfoDialog";
import SendInvitationFormDialog from "./dialogs/SendInvitationFormDialog";
import SendReminderFormDialog from "./dialogs/SendReminderFormDialog";
import LocationsTable from "./table/LocationsTable";

const Locations = () => {
  let query = useQuery();
  const [showApproveForm, setShowApproveForm] = useState(false);
  const [showRejectForm, setShowRejectForm] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const selection = useSelector((s) => s.locations.selection);
  //  const locations = useSelector((s) => s.locations.locations);
  const showInvitationForm = useSelector((s) => s.locations.showInvitationForm);
  const clientId = useSelector((s) => s.clients.clientId);
  //  const client = useSelector((s) => s.clients.client);
  const showReminderForm = useSelector((s) => s.locations.showReminderForm);
  const showDeleteLocationDialog = useSelector(
    (s) => s.locations.deleteDialog.isOpen
  );
  const showApproveLocationDialog = useSelector(
    (s) => s.locations.showApproveLocationDialog
  );
  const showLocationInfoDialog = useSelector(
    (s) => s.locations.showLocationInfoDialog
  );

  const handleShowApproveForm = useCallback(() => {
    setShowApproveForm(true);
  }, []);

  const handleShowRejectForm = useCallback(() => {
    setShowRejectForm(true);
  }, []);

  const crmSideBarContext = useContext(CrmSideBarContext);

  useEffect(() => {
    const id = !clientId ? 0 : Number(clientId);
    const type = !clientId ? "" : 900000000003140;
    //const type = !clientId ? "" : client ? client.clientType : 900000000003140; //partnerData.businessObjectType;
    const url = "Rvm";
    if (crmSideBarContext) {
      crmSideBarContext.setupSidebar(id, type, url);
    }
  }, [crmSideBarContext, clientId]);

  useEffect(() => {
    if (clientId) {
      dispatch(loadLocationsForClientRoutine.trigger({ clientId }));
    }
  }, [clientId]);

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  useEffect(() => {
    const clientId = query.get("clientId");
    if (clientId) {
      dispatch(setClientId(clientId));
      dispatch(loadClientInfoRoutine.trigger({ clientId }));
    }
  }, [query]);

  useUnmount(() => {
    dispatch(resetState());
  });

  const tableSelection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: async () => {
          const selected = tableSelection.getSelection();

          await dispatch(setSelection(selected));
        },
      }),
    []
  );

  return (
    <>
      <LocationsHeader
        tableSelection={tableSelection}
        handleShowRejectForm={handleShowRejectForm}
        handleShowApproveForm={handleShowApproveForm}
      />
      <LocationsTable selection={tableSelection} />
      {showInvitationForm ? (
        <SendInvitationFormDialog
          selection={tableSelection}
          values={selection}
          coveragePeriodFromDate={
            selection.length === 1 && selection[0].coveragePeriodFromDate
              ? moment(selection[0].coveragePeriodFromDate)
                  .add(1, "year")
                  .toDate()
              : moment().endOf("year").toDate()
          }
          coveragePeriodToDate={
            selection.length === 1 && selection[0].coveragePeriodToDate
              ? moment(selection[0].coveragePeriodToDate)
                  .add(1, "year")
                  .toDate()
              : moment().endOf("year").add(1, "year").toDate()
          }
          close={() => {
            dispatch(setShowInvitationForm(false));
          }}
        />
      ) : null}
      {showReminderForm ? (
        <SendReminderFormDialog
          selection={tableSelection}
          values={selection}
          close={() => {
            dispatch(setShowReminderForm(false));
          }}
        />
      ) : null}
      {showDeleteLocationDialog ? (
        <DeleteLocationDialog
          values={selection}
          close={() => {
            dispatch(hideDeleteLocationDialog({}));
          }}
        />
      ) : null}
      {showApproveLocationDialog ? (
        <ApproveLocationDialog
          close={() => {
            dispatch(setShowApproveLocationDialog(false));
          }}
        />
      ) : null}
      {showApproveForm ? (
        <ApproveFormDialog
          close={() => {
            setShowApproveForm(false);
          }}
        />
      ) : null}
      {showRejectForm ? (
        <RejectFormDialog
          close={() => {
            setShowRejectForm(false);
          }}
        />
      ) : null}
      {showLocationInfoDialog ? (
        <LocationInfoDialog
          close={() => {
            dispatch(setShowLocationInfoDialog(false));
          }}
        />
      ) : null}

      <Dialog />
    </>
  );
};

export default Locations;
