import { css } from "@emotion/css";
import { useField } from "formik";
import { Label } from "office-ui-fabric-react";
import React from "react";
import { VSpace } from "../Spacer";
import { ErrorMessage, StyledTextField } from "../styled";
import { TextFieldConfig } from "./types";
import { useDynamicConfig } from "./useDynamicConfig";

type Props = { fieldConfig: TextFieldConfig };

export const TextField = ({ fieldConfig }: Props) => {
  const config = {
    ...fieldConfig,
    ...useDynamicConfig(fieldConfig.d),
  };
  const {
    name,
    label,
    isVisible = true,
    isDisabled,
    isResizable,
    isMultiline,
    rows,
    isRequired,
    validate,
    background,
  } = config;
  const [field, { error }] = useField({ name, validate });

  if (!isVisible) return null;

  return (
    <div
      className={css`
        flex: 1;
      `}
    >
      <Label required={isRequired} disabled={isDisabled}>
        {label}
      </Label>
      <StyledTextField
        id={name}
        borderless
        disabled={isDisabled}
        multiline={isMultiline}
        resizable={isResizable}
        rows={rows}
        hasError={!!error}
        background={background}
        {...field}
      />
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
      <VSpace height={10} />
    </div>
  );
};
