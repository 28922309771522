import { useTheme } from "@emotion/react";
import { mergeStyleSets, Text, TextField } from "office-ui-fabric-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";

const getTextFieldStyle = (transparent, theme, isMarked) => ({
  root: {
    ".ms-TextField-fieldGroup": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
      background: transparent ? "transparent" : "inherit",
      ...(isMarked && { background: theme.palette.yellowLight }),
    },
    ".ms-TextField-fieldGroup::after": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
    },
    ".ms-TextField-fieldGroup input": {
      ...(isMarked && theme.isDark && { color: "black" }),
    },
    ".ms-TextField-fieldGroup input:disabled": {
      background: transparent ? "transparent" : "inherit",
    },
    ".ms-TextField-fieldGroup textarea:disabled": {
      background: theme.palette.white,
    },
  },
});

type Props = {
  value: string;
  onChange: any;
  name: string;
  disabled: boolean;
  label: string;
  errors?: any;
  transparent?: boolean;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  isMarked?: boolean;
  [x: string]: any; //rest props
};

const TextInputField = ({
  disabled,
  label,
  name,
  onChange,
  error = "",
  value,
  setFieldValue = () => {},
  errors,
  transparent,
  required,
  isMarked,
  ...props
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isDarkMode = theme.isDark;
  const classes = mergeStyleSets({
    label: {
      fontSize: "14px",
      fontWeight: "600",
      padding: "5px",
      display: "block",
      color: (function () {
        if (disabled) {
          return theme.palette.neutralSecondaryAlt;
        }
        if (isDarkMode) {
          return "white";
        }
        return theme.palette.black;
      })(),
    },
    labelAsterix: {
      color: "rgb(164, 38, 44)",
    },
  });

  const styles = getTextFieldStyle(transparent, theme, isMarked);
  const onRenderLabel = () => (
    <Text className={classes.label}>
      {t(label)} <Text className={classes.labelAsterix}>{required && "*"}</Text>
    </Text>
  );

  useEffect(() => {
    if (value) {
      setFieldValue(name, value);
    }
  }, [name, value]);

  return (
    <FieldContainer isTooltipHidden={disabled} tooltipText={label}>
      <>
        <TextField
          name={name}
          onRenderLabel={onRenderLabel}
          styles={styles}
          disabled={disabled}
          label={t(label)}
          onChange={onChange}
          value={value}
          errorMessage={name && errors[name] ? " " : undefined}
          autoComplete="off"
          {...props}
        />
        {errors[name] && (
          <ErrorMsg id={name + "_error"}>{errors[name]}</ErrorMsg>
        )}
      </>
    </FieldContainer>
  );
};

export default React.memo(TextInputField);
