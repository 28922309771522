import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../store/store";
import useIsExternalUser from "./useIsExternalUser";

import { setExcelDialog } from "../store/valueMonitoring/valueMonitoringPage";
import useDocumentList from "./useDocumentList";

function useDocCommandBarItems() {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const documents = useDocumentList();

  const isExternalUser = useIsExternalUser();

  const commandBarItems = useMemo(() => {
    const data = [];
    const documentItem = {
      key: "documents",
      text: t("rvm.templatedocuments.label"),
      iconProps: { iconName: "Documentation" },

      subMenuProps: {
        items: [],
      },
    };

    for (let index = 0; index < documents.length; index++) {
      const documentRaw = documents[index];
      if (!documentRaw) return;

      const document = {
        name: documentRaw?.Name || documentRaw?.name,
        url: documentRaw?.Url || documentRaw?.url,
      };
      // const fileExtension = file.name.split(".")[1];
      const fileExtension = document?.name
        .split("/")
        .pop()
        .split("#")[0]
        .split("?")[0];

      let iconName = "TextDocument";
      if (
        fileExtension.toLowerCase().includes("ppt") ||
        fileExtension.toLowerCase().includes("pptx")
      )
        iconName = "PowerPointDocument";
      if (
        fileExtension.toLowerCase().includes("xls") ||
        fileExtension.toLowerCase().includes("xltx")
      )
        iconName = "ExcelDocument";
      if (
        fileExtension.toLowerCase().includes("doc") ||
        fileExtension.toLowerCase().includes("docx")
      )
        iconName = "WordDocument";
      if (document) {
        documentItem.subMenuProps.items.push({
          key: "file" + index,
          text: document.name,
          iconProps: { iconName: iconName },
          preferMenuTargetAsEventTarget: true,
          onClick: () => {
            window.open(document.url);
          },
        });
      }
    }

    if (documentItem.subMenuProps?.items?.length) {
      data.push(documentItem);
    }

    if (isExternalUser) {
      data.push({
        key: "generateExcelReport",
        text: t("rvm.generateExcelReport.label"),
        iconProps: { iconName: "ExcelLogo" },

        onClick: () => {
          dispatch(
            setExcelDialog({
              isOpen: true,
              title: t("rvm.generateExcelReport.label"),
              icon: "ExcelLogo",
            })
          );
        },
      });
    }

    return data;
  }, [dispatch, documents, isExternalUser, t]);

  return commandBarItems;
}

export default useDocCommandBarItems;
