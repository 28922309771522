import { CommandBarButton, IIconProps, mergeStyleSets } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  getCampaignHistory,
  getCampaignInfo,
  selectCampaignHistory,
  selectCampaignInfo,
} from "../../../store/campaign/campaignSlice";
import { useSelector } from "../../../store/hooks";
import CampaignDialog from "./CampaignDialog";

const configIcon: IIconProps = { iconName: "CampaignTemplate" };

type Props = {
  disableOpenCampaign?: boolean;
};

function Campaign({ disableOpenCampaign }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const client = useSelector((s) => s.clients.client);

  const campaignHistory = useSelector(selectCampaignHistory);
  const campaignInfo = useSelector(selectCampaignInfo);
  const dataLoading = useSelector((s) => s.campaign.campaignDataLoading);

  const clientId = client?.clientId;

  const [showDialog, setShowDialog] = useState(false);

  const getCampaignData = useCallback(async () => {
    if (!clientId) return;

    dispatch(getCampaignInfo({ clientId }));
    dispatch(getCampaignHistory({ clientId }));
  }, [clientId]);

  useEffect(() => {
    if (!clientId) return;
    getCampaignData();
  }, [clientId]);

  const handleToggleShowDialog = useCallback(() => {
    setShowDialog((prev) => !prev);
  }, []);

  const campaignClosed = campaignInfo?.isCampaignClosed || !campaignInfo;

  const statusText = dataLoading
    ? t("greco.loading")
    : campaignClosed
    ? t("rvm.openCampaign.label")
    : t("rvm.closeCampaign.label");

  if (dataLoading) return <></>;

  const campaignOpenDisabled = disableOpenCampaign && campaignClosed;

  return (
    <>
      <CampaignDialog
        campaignInfo={campaignInfo}
        showDialog={showDialog}
        toggleShowDialog={handleToggleShowDialog}
        campaignHistory={campaignHistory}
        title={statusText}
        getCampaignData={getCampaignData}
      />
      <CommandBarButton
        disabled={!clientId || campaignOpenDisabled}
        onClick={handleToggleShowDialog}
        iconProps={configIcon}
        className={classes.buttonClass}
        text={statusText}
      />
    </>
  );
}

export default Campaign;

const classes = mergeStyleSets({
  buttonClass: {
    display: "flex",
    alignItems: "center",
    height: 32,
    ".ms-Button-icon": {
      height: "inherit",
      display: "flex",
      alignItems: "center",
    },
  },
});
