import { useTheme } from "@emotion/react";
import { ITextStyles, Icon, TextField } from "@fluentui/react";
import React, { useState } from "react";
import { Flex } from "../../../components/styled";
import { debounce } from "../../../utils/utils";

type Props = {
  defaultValue: string;
  handleChange: (event: any) => void;
  handleDelete: () => void;
  handleUpdateConfigurationSettings: () => void;
};

const textStyles: Partial<ITextStyles> = {
  root: { display: "flex", flex: 1 },
};

function DocumentInputField({
  defaultValue,
  handleChange,
  handleDelete,
  handleUpdateConfigurationSettings,
}: Props) {
  const theme = useTheme();
  const [isDirty, setIsDirty] = useState(false);

  const onChange = (ev: any) => {
    ev.persist();
    debounce(handleChange(ev), 1500);
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  return (
    <>
      <Flex justifyContent="space-between" gap={20} alignItems="center">
        <TextField
          onChange={onChange}
          styles={textStyles}
          underlined
          defaultValue={defaultValue}
        />
        <Icon
          styles={{
            root: {
              cursor: "pointer",
              color: theme.palette.red,
            },
          }}
          iconName="Delete"
          onClick={handleDelete}
        />

        <Icon
          styles={{
            root: {
              cursor: isDirty ? "pointer" : "not-allowed",
              fontWeight: 600,
              color: isDirty
                ? theme.palette.themeDark
                : theme.palette.neutralLight,
            },
          }}
          iconName="save"
          onClick={() => {
            if (!isDirty) return;
            handleUpdateConfigurationSettings();
            setIsDirty(false);
          }}
        />
      </Flex>
    </>
  );
}

export default DocumentInputField;
