import {
  getTheme,
  IStyle,
  mergeStyleSets
} from '@uifabric/experiments/lib/Styling';
import React from 'react';

const theme = getTheme();

export const headerHeight = 50;
export const CommandBarHeight = 44;
export const CommandBarWidth = 1350;
export const FormTabsHeight = 44;
export const ProgressIndicatorHeight = 36;
export const MessageBarHeight = 32;
export const MessageBarWarningWidth = 600;
export const MessageBarLargeWarningWidth = 800;
export const MessageBarWarningHeight = 45;
export const MessageBarWarningZindex = 9999;
export const sideBarWidth = 55;
export const sideBarMargin = 50;
export const formWidth: number = 1200;
export const modalFormWidth = 850;
export const policyModalFormWidth = 900;
export const columns: any = {
  policyVersionStatus: {
    width: 60
  },
  claimStatusGroup: {
    width: 80
  },
  internalNumber: {
    width: 80
  },
  licensePlateNumber: {
    width: 97
  },
  eventDateTime: {
    width: 108
  },
  deductibleAmount: {
    width: 85
  },
  claimAmount: {
    width: 108
  },
  settlementDate: {
    width: 70
  },
  insurerPolicyNumber: {
    width: 125
  }
};
export const searchDetailsList = {
  defaultCellXPadding: 20
};

export const centerElements: any = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};

export const centerElementFlex: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const sideBarStyle: any = {
  position: 'fixed',
  top: headerHeight,
  zIndex: 9995,
  height: '100%',
  width: sideBarWidth
};

export const noContentText: any = {
  textAlign: 'center',
  fontSize: '1.4rem',
  fontWeight: 600,
  color: theme.palette.neutralSecondary
};

export const scrollToTop = (
  scrolElement: React.RefObject<any>,
  bottom?: any
) => {
  scrolElement.current._root.current.childNodes[1].scrollTop = 0;
  if (bottom) {
    bottom.current = 40;
  }
};

const link = {
  selectors: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }
};

export const iconLinkStyles = mergeStyleSets({
  itemWrapper: {
    display: 'flex !important',
    alignItems: 'center !important'
  },
  icon: {
    marginRight: 5,
    display: 'flex',
    alignItems: 'center'
  },
  link,
  textLink: {
    ...link,
    width: '300px',
    overflow: 'hidden',
    height: '24px'
  },
  textReadOnly: {
    ...link,
    width: '650px',
    overflow: 'hidden',
    height: '20px'
  }
});

export const errorStyle: React.CSSProperties = {
  fontSize: '12px',
  fontWeight: 400,
  color: 'rgb(164, 38, 44)',
  margin: '0px',
  paddingTop: '5px'
};
