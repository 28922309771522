import { useFormikContext } from "formik";
import { useEffect } from "react";
import { stringToNumber } from "../../../utils/utils";

export const useTotalPdField = () => {
  const formik = useFormikContext();
  const buildingValues =
    formik.values["buildingValues"] && formik.values["buildingValues"] !== ""
      ? stringToNumber(formik.values["buildingValues"])
      : 0;
  const equipmentValues =
    formik.values["equipmentValues"] && formik.values["equipmentValues"] !== ""
      ? stringToNumber(formik.values["equipmentValues"])
      : 0;
  const stockValues =
    formik.values["stockValues"] && formik.values["stockValues"] !== ""
      ? stringToNumber(formik.values["stockValues"])
      : 0;

  useEffect(() => {
    const totalPdValue = buildingValues + equipmentValues + stockValues;
    const totalPdString = totalPdValue.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (formik.dirty && !formik.isSubmitting) {
      formik.setFieldValue("totalPd", totalPdString);
    }
  }, [
    buildingValues,
    formik.touched["buildingValues"],
    formik.values["buildingValues"],
    equipmentValues,
    formik.touched["equipmentValues"],
    formik.values["equipmentValues"],
    stockValues,
    formik.touched["stockValues"],
    formik.values["stockValues"],
  ]);
};
