import React from 'react';
import { mergeStyles, Shimmer, ShimmerElementType } from 'office-ui-fabric-react';

export const ClientBasicTableInfoShimmer = (): JSX.Element => {
    const wrapperClass = mergeStyles({
        selectors: {
            '& > .ms-Shimmer-container': {
                margin: '5px 0'
            },
            '& > .ms-Shimmer-container:first-of-type': {
                margin: '0 0 0 0'
            }
        }
    });

    const shimmerHeight = 16;

    return (
        <div style={{ width: '97%', padding: '20px 20px 10px 30px' }}>
            <div className={wrapperClass}>
                <div style={{ width: '10%' }}>
                    <Shimmer
                        height={shimmerHeight}
                        styles={{ root: { paddingBottom: '3px' } }}
                        shimmerElements={[
                            {
                                type: ShimmerElementType.line,
                                width: '20%',
                                height: shimmerHeight
                            }
                        ]}
                    />
                    <Shimmer
                        height={shimmerHeight}
                        styles={{ root: { paddingBottom: '3px' } }}
                        shimmerElements={[
                            {
                                type: ShimmerElementType.line,
                                width: '20%',
                                height: shimmerHeight
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}